import { assetConstants } from '../constants/asset';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {

		case assetConstants.GET_ASSETS:

			return { 
				...state, 
				files: _.mapKeys(action.payload.data, 'id')
			};
			
		case assetConstants.GET_ASSET:
		case assetConstants.SAVE_ASSET:

			let files = state.files ? state.files : {};
			
			files[action.payload.data.id] = action.payload.data;
			
			return { 
				...state, 
				files: files
			};
			
		case assetConstants.DELETE_ASSET:
		
			files = state.files ? state.files : {};
			
			if(files[action.id]){
				delete(files[action.id]);
			}
			
			return { 
				...state, 
				files: files
			};	
			
		default:
			return state;
	}
}