import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Base64 } from 'js-base64';
import _ from 'lodash';

const Nav = props => {
	
	const account = useSelector(state => state.account);
	const [open, setOpen] = useState(false);
	const location = useLocation();

	const closeNav = () => {
		setOpen(false);
	}

	const createNavLink = (key, title, url, icon) => {

		return (
			<li key={key} className={`${title} ${key}`}>
				<Link to={url} onClick={() => { closeNav() }}>
					<i className={icon}></i>
					{title}
				</Link>
			</li>
		);
	}
	
	const createNavExtLink = (key, title, url, icon) => {

		return (
			<li key={key} className={`${title} ${key}`}>
				<a href={url} target="_blank">
					<i className={icon}></i>
					{title}
				</a>
			</li>
		);
	}

	const createNavButton = (key, title, url) => {

		return (
			<li key={key}>
				<Link to={url} onClick={() => { closeNav() }} className="btn ">
					{title}
				</Link>
			</li>
		);
	}
	
	let navOptions = false;
	
	if(account.role){

		let options = [];
		let quicklinks = [];
		let secondaryOptions = []; // ['settings'];

		switch(account.role){

			case 'ROLE_SUPERADMIN':
			case 'ROLE_ADMIN':
				options = [
					'brands',
					'hub',
					'items',
					'groups',
					'alerts',
					'users',
					'history',
					'assets',
					'notifications'
				];
				
				quicklinks = [
					
				];
				break;				
		}
		
		secondaryOptions.push('logout');

		navOptions = [options, quicklinks, secondaryOptions];
	}
		
	return (
		<nav 
			onMouseLeave={closeNav}
			className={open ? 'active' : ''}
		>
			<i 
				id="hamburger" 
				className="fal fa-bars"
				onMouseEnter={() => { setOpen(true); }}
				onClick={() => { setOpen(true); }}
			></i>
			
			{navOptions[0] && 
				
				<ul>
					{_.map(navOptions[0], option => {
	
						switch(option){
		
							case 'hub':
								return createNavLink(option, 'Hub', '/hubs', 'fal fa-photo-video');
								break;
								
							case 'items':
								return createNavLink(option, 'Items', '/items', 'fal fa-list');
								break;
								
							case 'brands':
								return createNavLink(option, 'Dashboard', '/brands', 'fal fa-building');
								break;
								
							case 'users':
								return createNavLink(option, 'Users', '/users', 'fal fa-user-friends');
								break;
							
							case 'groups':
								return createNavLink(option, 'Groups', '/groups', 'fal fa-tag');
								break;
								
							case 'assets':
								return createNavLink(option, 'Files', '/assets', 'fal fa-folder-open');
								break;
								
							case 'history':
								return createNavLink(option, 'History', '/history', 'fal fa-history');
								break;
								
							case 'alerts':
								return createNavLink(option, 'Alerts', '/alerts', 'fal fa-bell');
								break;
								
							case 'notifications':
								return createNavLink(option, 'Notify', `${location.pathname}/compose`, 'fal fa-envelope');
								break;
		
							case 'settings':
								return createNavLink(option, 'Settings', '/settings', 'fal fa-cog');
								break;	
		
							case 'logout':
								return createNavLink(option, 'Logout', '/logout', 'fal fa-sign-out');
								break;
		
						}
		
					})}
				</ul>
			}

			{navOptions[1].length > 0 && 
	
				<div id="quicklinks">
					<ul>
						{_.map(navOptions[1], quicklink => {
	
							switch(quicklink){
			
								// TODO
							}
			
						})}
					</ul>
										
					<a href="#" id="quicklinks-toggle">
						<i className="far fa-plus-circle"></i>
						Quicklinks
					</a>
				</div>
			}
			
			{navOptions[2].length > 0 && 
				
				<ul className="secondary">
					{_.map(navOptions[2], secondaryOption => {
		
						switch(secondaryOption){
		
							case 'settings':
								return createNavLink(secondaryOption, 'Settings', '/settings', 'fal fa-cog');
								break;
		
							case 'logout':
								return createNavLink(secondaryOption, 'Logout', '/logout', 'fal fa-sign-out');
								break;
						}
					})}
				</ul>
			}
		</nav>
	);
}

export default Nav;
