import { api } from '../helpers/api';
import { brandConstants } from '../constants/brand';

export const getBrands = () => async dispatch => {
    
    try{
        const request = await api().get('/brand/brands');
        
        dispatch({
			type: brandConstants.GET_BRANDS,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const getBrand = (id) => async dispatch => {
    
    try{
        const request = await api().get(`/brand/brands/${id}`);
        
        dispatch({
			type: brandConstants.GET_BRAND,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const saveCode = (id, values) => async dispatch => {
	
	let url = '/brand/code';
	
	if(id){
		url += `/${id}`;
	}
	
	try{
		const request = await api().post(url, values);
		
		dispatch({
			type: brandConstants.SAVE_CODE,
			payload: request
		});
		
		return request.data.id;
	}
	catch(error){
		console.log('error', error)
		
		if(error.response && error.response.data && error.response.data.status == 'dupe'){
			return 'dupe';
		}
	}
}

export const deleteCode = (id) => async dispatch => {
	
	try{
		const request = await api().delete(`/brand/code/${id}`);
		
		dispatch({
			type: brandConstants.DELETE_CODE,
			id: id
		});
		
		return true;
	}
	catch(error){
		 console.log('error', error)
	}
}