import { api } from '../helpers/api';
import { groupConstants } from '../constants/group';

export const getGroups = (brand) => async dispatch => {
	
	try{
		const request = await api({ brand: brand }).get(`/group/groups`);
		
		dispatch({
			type: groupConstants.GET_GROUPS,
			payload: request
		});
		
		return true;
	}
	catch(error){
		 console.log('error', error)
	}
}

export const saveGroup = (id, values) => async dispatch => {
	
	let url = '/group/save';
	
	if(id){
		url += `/${id}`;
	}
	
	try{
		const request = await api().post(url, values);
		
		dispatch({
			type: groupConstants.SAVE_GROUP,
			payload: request
		});
		
		return request.data.id;
	}
	catch(error){
		 console.log('error', error)
	}
}

export const deleteGroup = (id) => async dispatch => {
	
	try{
		const request = await api().delete(`/group/delete/${id}`);
		
		dispatch({
			type: groupConstants.DELETE_GROUP,
			id: id
		});
		
		return true;
	}
	catch(error){
		 console.log('error', error)
	}
}
