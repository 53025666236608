import { accountConstants } from '../constants/account';

export default function(state = {}, action) {
	
	switch (action.type) {
		      		
		case accountConstants.LOGIN:
				
			localStorage.setItem('user_id', action.payload.data.id);
			localStorage.setItem('user_token', action.payload.data.token);
		
			return { 
				...state, 
				id: action.payload.data.id,
				token: action.payload.data.token,
				profile: action.payload.data.profile,
				role: action.payload.data.role
			};
			
		case accountConstants.PROFILE:

			return { 
				...state, 
				profile: action.payload.data.profile,
				role: action.payload.data.role
			};	
			
		default:
			return state;
	}
}