import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'; 
import { accountLogout } from '../../actions/account';

const Logout = props => {
	
	const dispatch = useDispatch();
	
	useEffect(() => {
		console.log('useEffect', 'Logout')
		dispatch(accountLogout());
	}, [dispatch]);

	return null;
}

export default Logout;