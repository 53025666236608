import React, { useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';

const Button = props => {
	
	const [toggleOver, setToggleOver] = useState(false);
	const [subOver, setSubOver] = useState(false);
	const [subOpen, setSubOpen] = useState(false);
	
    const {
		type,
		submitting,
		label,
		className
	} = props;
    
    let button = '';
    		
	if(props.url){
		
		button = (
			<Link 
				className={`btn ${className ? className : ''}`}
				to={props.url}
			>
				{props.label}
			</Link>
		);

	}else{
		
		button = (
			<button 
				className={`btn ${className ? className : ''}`}
				onClick={props.onClick ? props.onClick : null}
				type={type ? type : 'submit'}
				disabled={submitting}
			>
				{props.label}
			</button>
		);
	}
	
	if(props.split && props.split.length > 0){
			
		return (
			<div className={`btn-split ${(subOpen || subOver) ? 'open' : ''}`}>
				{button}
				
				<span 
					className="toggle" 
					onMouseEnter={() => { setSubOpen(true) }} 
					onMouseLeave={() => { setSubOpen(false) }} 
					onClick={() => { if(subOver){ setSubOpen(false); }else{ setSubOpen(true); } }} 
				/>

				<ul onMouseEnter={() => { setSubOver(true) }} onMouseLeave={() => { setSubOver(false) }}>
					{_.map(props.split, (option, key) => {
						
						let link = '';
						
						if(option.url){
		
							link = (
								<Link to={option.url}>
									{option.label}
								</Link>
							);
					
						}else if(option.onClick){
							
							link = (
								<button 
									onClick={option.onClick}
									type="button"
								>
									{option.label}
								</button>
							);
						}
						
						return (
							<li key={key}>
								{link}
							</li>	
						);
					})}
				</ul>
			</div>
		);
		
	}else {
		return button;
	}
}

export default Button;