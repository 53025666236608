import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const Tabs = ({ options, active, onClick }) => {
	
	let className = '';
	let label = '';
	
	return (
		<ul className="tabs">
			{_.map(options, (option, key) => {
				
				if(active == option.id){
					className = 'active';
				}else{
					className = '';
				}
				
				if(option.clickable || typeof option.clickable === 'undefined'){
					label = (
						<a className="label" href="#" onClick={(e) => { 
							e.preventDefault();
							onClick(option.id);
						}}>{option.label}</a>
					);
				}else{
					label = (
						<span className="label">{option.label}</span>
					);
				}
				
				if(option.complete){
					className += ' complete';
				}
								
				return (
					<li className={className} key={key}>
						{label}
			  		</li>
				);
			})}
    	</ul>			
	);
}

export default Tabs;
