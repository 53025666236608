import { api } from '../helpers/api';
import { accountConstants } from '../constants/account';

export const accountLogin = (values) => async dispatch => {
    
    try{
        const request = await api().post('/user/authenticate', { email: values.email, password: values.password });
        
        dispatch({
			type: accountConstants.LOGIN,
			payload: request
		});
		
		return true;
    }
    catch(error){
        console.log('error', error)
    }
}

export const accountProfile = () => async dispatch => {
    
    try{
        const request = await api().get('/user/profile');
        
        dispatch({
			type: accountConstants.PROFILE,
			payload: request
		});
		
		return true;
    }
    catch(error){
        localStorage.clear();
		window.location = '/login';
    }
}

export const accountLogout = () => async dispatch => {
    
    try{
        const request = await api().get('/user/logout');
        
        dispatch({
			type: accountConstants.LOGOUT,
			payload: request
		});
		
		localStorage.clear();
		window.location = '/login';
    }
    catch(error){
        localStorage.clear();
		window.location = '/login';
    }
}