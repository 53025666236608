import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import RoutesTemplated from './routes/templated';

import ScreenLogin from './screens/auth/login';

const middleware = [thunk]
const store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(...middleware)))

const App = () => (
	<Provider store={store}>
		<BrowserRouter>
			<Switch>
												
				{/* NON TEMPLATED ROUTES */}
				<Route path="/login" component={ScreenLogin} exact />

				{/* TEMPLATED ROUTES */}
				<Route path="*" component={RoutesTemplated} />
						
						
			</Switch>
		</BrowserRouter>
	</Provider>
);
  
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);