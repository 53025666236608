import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getBrands } from '../../actions/brand';
import { saveGroup, getGroups, deleteGroup } from '../../actions/group';
import _ from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
const GroupEdit = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { id } = useParams();
	
	if(!id && !query.get('brand')){
		props.history.push(props.parentPath);
	}

	const [selectedBrand, setSelectedBrand] = useState(query.get('brand'));
	const brand = useSelector(state => state.brand);
	const group = useSelector(state => state.group);
			
	useEffect(() => {
		dispatch(getBrands());
		dispatch(getGroups(selectedBrand));
	}, []);	
	
	useEffect(() => {
		
		if(id && group.groups_all && group.groups_all[id]){
			const thisGroup = group.groups_all[id];
			props.change('name', thisGroup.name);
			setSelectedBrand(thisGroup.brand_id);
		}
	}, [group]);	

	if((id && (!group.groups || !group.groups[selectedBrand] || !group.groups_all[id]))){
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {
		
		let posting = {
			brand: selectedBrand, 
			name: values.name
		}
				
		dispatch(saveGroup(
			id ? id : false, 
			posting
		)).then((id) => {
			props.history.push(props.parentPath);
		});
	}

	return (
		<div>
			<div className="header">
				<h3>
					{id ? 'Edit Group' : 'Add Group'}
				</h3>
			</div>

			<form onSubmit={handleSubmit(Submit)}>			
								
				<Field 
					name="name" 
					type="text" 
					label="Title" 
					component={FormField} 
				/>				
				
				<div className="cta">
					<Button
						{...props}
						label="Save Group"
					/>
					
					{id && 
						<Button
							label="Delete"
							type="button"
							className="warning"
							onClick={() => {
								
								MySwal.fire({
									icon: 'question',
									title: 'Delete',
									text: 'Are you sure you wish to delete this group?',
									showCancelButton: true,
									customClass: {
										confirmButton: 'btn warning mr-10',
										cancelButton: 'btn'
									},
									buttonsStyling: false
								}).then((result) => {
									
									if(result.isConfirmed){
										dispatch(deleteGroup(
											id
										)).then((id) => {
											props.history.push(props.parentPath);
										});
									}
								});
							}}
						/>
					}
				</div>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.name) {
		errors.name = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'group',
	validate
})(GroupEdit);