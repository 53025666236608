import React from 'react';

const EmptySection = ({ title, icon, description, fullHeight }) => {

	let className = '';
	
	if(fullHeight){
		className = 'full';
	}

	return (
		<div className={`empty-section ${className}`}>
			<i className={icon}></i>
			<h3>{title}</h3>
			{description}
		</div>
	);
}

export default EmptySection;
