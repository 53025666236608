import React, { useEffect, useState } from 'react';
import { ModalRoute } from 'react-router-modal';
import { useSelector, useDispatch } from 'react-redux'; 
import { Route, Switch, Redirect } from 'react-router-dom';

import ModalWrapper from '../components/chrome/overlay';

import Layout from '../components/chrome/layout';
import Loading from '../components/chrome/loading';

import ScreenBrands from '../screens/brands';
import ScreenBrandsView from '../screens/brands/view';
import ScreenBrandsEdit from '../screens/brands/edit';

import ScreenUsers from '../screens/users';
import ScreenUsersView from '../screens/users/view';

import ScreenUsersGroups from '../screens/users/groups';

import ScreenAssets from '../screens/assets';
import ScreenAssetsEdit from '../screens/assets/edit';
import ScreenAssetsView from '../screens/assets/view';

import ScreenHubs from '../screens/hub';
import ScreenItemEdit from '../screens/hub/edit';
import ScreenItemView from '../screens/hub/view';
import ScreenCategoryEdit from '../screens/hub/category';

import ScreenItems from '../screens/hub/items';

import ScreenHistory from '../screens/history';

import ScreenGroups from '../screens/groups';
import ScreenGroupsEdit from '../screens/groups/edit';

import ScreenCodeEdit from '../screens/brands/code';

import ScreenAlerts from '../screens/alerts';
import ScreenAlertsView from '../screens/alerts/view';
import ScreenAlertsEdit from '../screens/alerts/edit';

import ScreenNotification from '../screens/notifications';
import ScreenNotificationCompose from '../screens/notifications/compose';


import ScreenLogout from '../screens/auth/logout';
import { accountProfile } from '../actions/account';

const RoutesTemplated = props => {
	
	if(!localStorage.getItem('user_id') || !localStorage.getItem('user_token')) {
		props.history.push('/login');
	}
	
	const dispatch = useDispatch();
	const account = useSelector(state => state.account);
	const [parent, setParent] = useState('/');
	
	useEffect(() => {
		console.log('useEffect', 'RoutesTemplated')
		dispatch(accountProfile());
	}, []);
	
	if(!account.profile){
		
		return (
			<Loading />
		);
		
	}else{

		const RouteProtected = ({ component: Component, ...rest }) => {
	
			if(localStorage.getItem('user_id') && localStorage.getItem('user_token')){
								
				if(rest.computedMatch && rest.computedMatch.url == window.location.pathname){
					setParent(window.location.pathname + window.location.search);
				}
				
				return (
					<Route {...rest} render={props => (
						<Component 
							{...props}
							parentPath={parent}
						/>
					)} />
				);
				
			}else{
				
				return(
					<Route {...rest} render={props => (
						<Redirect 
		            		to={`/login`} 
		            	/>
					)} />
				);
			}
		}
		
		const RouteUnprotected = ({ component: Component, ...rest }) => (
		    <Route {...rest} render={props => (
		        (localStorage.getItem('user_id') && localStorage.getItem('user_token'))
		            ? 
		            (
		            	<Redirect 
		            		to={{ pathname: '/', state: { from: props.location } }} 
		            	/>
		            )
		            :
		            (
		            	<Component {...props} />
		            )
		    )} />
		);
		
		return (
			<React.Fragment>
				<Layout>
					<Switch>
						<RouteProtected path="/logout" component={ScreenLogout} />
						
						<RouteProtected path="/items/:brand_id(\d+)" component={ScreenItems} />
						<RouteProtected path="/items" component={ScreenItems} />
						
						<RouteProtected path="/hubs/:brand_id(\d+)/:category_id(\d+)" component={ScreenHubs} />
						<RouteProtected path="/hubs/:brand_id(\d+)" component={ScreenHubs} />
						<RouteProtected path="/hubs" component={ScreenHubs} />
						<RouteProtected path="/assets" component={ScreenAssets} />
						
						<RouteProtected path="/groups/:brand_id(\d+)" component={ScreenGroups} />
						<RouteProtected path="/groups" component={ScreenGroups} />
						
						<RouteProtected path="/brands/:id(\d+)" component={ScreenBrandsView} />
						<RouteProtected path="/brands" component={ScreenBrands} />
						
						<RouteProtected path="/users/:id(\d+)" component={ScreenUsersView} />
						<RouteProtected path="/users" component={ScreenUsers} />
						
						<RouteProtected path="/alerts" component={ScreenAlerts} />
						
						<RouteProtected path="/history" component={ScreenHistory} />
												
						<RouteProtected path="/notifications" component={ScreenNotification} />
						
						<Route exact path="/" render={() => (<Redirect to="/brands" />)} />
						
						{/* NO MATCH, SHOW 404 */}
						<Route path="*" render={() => (<Redirect to="/login" />)} />
					</Switch>					 
				</Layout>
				<Switch>
					
					<ModalRoute path="*/brand/:id(\d+)" component={ScreenBrandsEdit} />
					<ModalRoute path="*/brand" component={ScreenBrandsEdit} />
					
					<ModalRoute path="*/groups/:user_id(\d+)/:brand_id(\d+)" component={ScreenUsersGroups} />
					
					<ModalRoute path="*/file/:id(\d+)/edit" component={ScreenAssetsEdit} />
					<ModalRoute path="*/file/:id(\d+)" component={ScreenAssetsView} />
					<ModalRoute path="*/file" component={ScreenAssetsEdit} />
					
					<ModalRoute path="*/item/:id(\d+)/edit" component={ScreenItemEdit} />
					<ModalRoute path="*/item/:id(\d+)" component={ScreenItemView} />
					<ModalRoute path="*/item" component={ScreenItemEdit} />
					
					<ModalRoute path="*/category/:id(\d+)/edit" component={ScreenCategoryEdit} />
					<ModalRoute path="*/category" component={ScreenCategoryEdit} />
					
					<ModalRoute path="*/group/:id(\d+)/edit" component={ScreenGroupsEdit} />
					<ModalRoute path="*/group" component={ScreenGroupsEdit} />
					
					<ModalRoute path="*/code/:brand_id(\d+)/:id(\d+)" component={ScreenCodeEdit} />
					<ModalRoute path="*/code/:brand_id(\d+)" component={ScreenCodeEdit} />
										
					<ModalRoute path="*/alert/:id(\d+)/edit" component={ScreenAlertsEdit} />
					<ModalRoute path="*/alert/:id(\d+)" component={ScreenAlertsView} />
					<ModalRoute path="*/alert" component={ScreenAlertsEdit} />
					
					<ModalRoute path="*/compose" component={ScreenNotificationCompose} />
					
				</Switch>
				<ModalWrapper 
					{...props} 
					parentPath={parent}
				/>

			</React.Fragment>
		);
	}
}

export default RoutesTemplated;