import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ModalContainer } from 'react-router-modal';
import { Animated } from "react-animated-css";

import "../../style/animate.min.css";

const ModalWrapper = props => {
					
	const [ready, setReady] = useState(false);
	const [visible, setVisible] = useState(false);
	
	const closeModal = () => {		
		props.history.push(props.parentPath);
		setVisible(false);
	}
	
	const escFunction = (event) => {
		
		if(visible && event.keyCode === 27) {
			closeModal();
		}
	}
	
	useEffect(() => {
		
		console.log('useEffect', 'Overlay')
		
		document.addEventListener("keydown", escFunction, false);
		
		return () => {
			document.removeEventListener("keydown", escFunction, false);
		}
		
	}, [visible]);
	
	let className = 'overlay';
	let closeButton = '';
	
	//console.log(WrappedComponent)

	
	//this.props.parentPath
	
	/*let { overlay } = false; 
	let isVisible = true;
	
					
	// FIRST PAGE LOAD
	if(overlay && typeof overlay.show == 'undefined'){
		className += ' hidden';
	}
	
	// SHOW OR HIDE
	if(!overlay || !overlay.show){
		isVisible = false;
	}

	// CLOSE BUTTON
	
	*/
	
	//if(overlay && overlay.closeUrl){
		closeButton = (
			<div className="close">
				<button onClick={closeModal}>
					<i className="fal fa-times"></i>
				</button>
			</div>
		)
	//}

	return (
		<Animated className={`${className} ${ready ? '' : 'hidden'}`} animationIn="slideInUp" animationOut="slideOutDown" isVisible={visible}>
			{closeButton}
			<div className="content">
				<div className="inner">
					<ModalContainer 
						onFirstModalMounted={() => {
							setReady(true)
							setVisible(true);
						}}
						onLastModalUnmounted={() => {
							setVisible(false);
						}}
						outDelay={250}
					/>
				</div>
			</div>
		</Animated>
	);
}

export default ModalWrapper;