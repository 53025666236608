import { api } from '../helpers/api';
import { notificationConstants } from '../constants/notification';

export const sendNotification = (values) => async dispatch => {
    
    try{
        const request = await api().post('/notification/send', values);
        
        dispatch({
			type: notificationConstants.SEND,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}