import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getItem, saveCategory, getCategories, deleteCategory } from '../../actions/hub';
import { getGroups } from '../../actions/group';
import _ from 'lodash';
import { imageResize, imageUrl } from '../../helpers/s3';
import { getAssets } from '../../actions/asset';
import AssetPicker from '../assets/picker';
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
const CategoryEdit = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { id } = useParams();
	
	if(!id && !query.get('brand')){
		props.history.push(props.parentPath);
	}

	const asset = useSelector(state => state.asset);
	const brand = useSelector(state => state.brand);
	const hub = useSelector(state => state.hub);
	const group = useSelector(state => state.group);
	
	let parent = false;
	
	if(query.get('parent')){
		
		if(query.get('parent') == 'false'){
			parent = '';
		}else{
			parent = query.get('parent');
		}
	}
	
	const [selectedBrand, setSelectedBrand] = useState(query.get('brand'));
	const [selectedParent, setSelectedParent] = useState(parent);
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [image, setImage] = useState(false);
	
	useEffect(() => {
		dispatch(getAssets());
		dispatch(getCategories());
		
		if(selectedBrand){
			dispatch(getGroups(selectedBrand));
		}
	}, []);	
	
	useEffect(() => {
		
		if(id && hub.categories_all && hub.categories_all[id]){
			
			const category = hub.categories_all[id];
			const parent = category.parent_id !== null ? category.parent_id : '';
						
			props.change('title', category.title);
			props.change('parent', parent);
			props.change('sortorder', category.sortorder);
			
			setSelectedParent(parent);
			setSelectedBrand(category.brand_id);
			dispatch(getGroups(category.brand_id));
			setSelectedGroups(_.map(category.groups, (value) => value.group_id));
			
			if(category.asset_id){
				setImage(category.asset_id);
			}
		}
	}, [hub]);	
	
	if(!asset.files || !group.groups || (id && (!hub.categories || !hub.categories[selectedBrand] || !hub.categories_all[id]))){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {
		
		if(!image){
			return false;
		}
		
		let posting = {
			brand: selectedBrand, 
			title: values.title,
			parent: selectedParent,
			image: image,
			sortorder: values.sortorder,
			groups: selectedGroups
		}
				
		dispatch(saveCategory(
			id ? id : false, 
			posting
		)).then((id) => {
			props.history.push(props.parentPath);
		});
	}
	
	const Asset = ({ id }) => {
				
		if(asset.files && asset.files[id]){
		
			if(asset.files[id].type == 'video'){
				return (
					<div className="video-container">
						<ReactPlayer 
							url={imageUrl(asset.files[id].filename)} 
							controls={true}
							width='100%'
							height='100%'
							className='react-player'
							config={{ file: { 
								attributes: {
									controlsList: 'nodownload'
								}
							}}}
						/>
					</div>
				)
			}else{
				return (
					<img src={imageResize(asset.files[id].filename, 500)} width="100%" />
				);
			}
		}
		
		return null;
	}	
	
	
	let parents = [{
		value: '',
		label: 'None - root category'
	}];
	
	const organiseCategories = (level, id) => {
		
		let categories =_.sortBy( _.filter(hub.categories[selectedBrand], { parent_id: id }), ['sortorder']);
		
		_.forEach(categories, (child, key) => {
			
			let label = child.title;
			
			if(level > 0){
				label = '- '.repeat(level) + label;

			}
			
			parents.push({
				value: child.id,
				label: label
			});
			
			categories[key].children = organiseCategories(level+1, child.id);
		});
		
		return categories;
	}
	
	organiseCategories(0, null);

	return (
		<div>
			<div className="header">
				<h3>
					{id ? 'Edit Category' : 'Add Category'}
				</h3>
			</div>

			<form onSubmit={handleSubmit(Submit)}>			
								
				<Field 
					name="title" 
					type="text" 
					label="Title" 
					component={FormField} 
				/>
				
				<FormField
					name="parent"
					label="Parent Category"
					type="suggest"
					noControl={true}
					options={parents}
					selected={selectedParent}
					onChangeFunc={(selected) => { 
						setSelectedParent(selected.value);
					}}
				/>
				
				<Field 
					name="sortorder" 
					type="number" 
					label="Sort Order" 
					component={FormField} 
				/>
				
				{!_.isEmpty(group.groups[selectedBrand]) && 
					<FormField
						name="groups"
						label="Restrict to group(s)"
						placeholder="Select groups(s) or leave blank"
						type="suggest"
						noControl={true}
						sortorder={true}
						isMulti
						options={_.map(group.groups[selectedBrand], (value, key) => {
							return(
								{
									label: value.name,
									value: value.id
								}
							);
						})}
						selected={selectedGroups}
						onChangeFunc={(selected) => { 
							setSelectedGroups(_.map(selected, (item) => {
								return item.value;
							}));
						}}
					/>
				}
				
				<div className="row">
					
					<div className="col col-12 col-sm-6 col-md-3 mb-30">
						<label className="label-main">Image</label>
								
						{!image &&
							<p>No image selected.</p>
						|| 
							<Asset id={image} />
						}
							
						<AssetPicker
							button={{
								label: image ? 'Change' : 'Choose',
								className: 'secondary medium  mt-10'
							}}
							max="1"
							types={['image']}
							onSelect={(files) => {										
		
								setImage(files[0]);
							}}
							selected={image ? [image] : []} 
						/>

					</div>					
				</div>
				
				<div className="cta">
					<Button
						{...props}
						label="Save Category"
					/>
					
					{id && 
						<Button
							label="Delete"
							type="button"
							className="warning"
							onClick={() => {
								
								MySwal.fire({
									icon: 'question',
									title: 'Delete',
									text: 'Are you sure you wish to delete this category and all of its contents?',
									showCancelButton: true,
									customClass: {
										confirmButton: 'btn warning mr-10',
										cancelButton: 'btn'
									},
									buttonsStyling: false
								}).then((result) => {
									
									if(result.isConfirmed){
										dispatch(deleteCategory(
											id
										)).then((id) => {
											props.history.push(props.parentPath);
										});
									}
								});
							}}
						/>
					}
				</div>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.title) {
		errors.title = 'Required'
	}
	
	if(!values.parent) {
		errors.parent = 'Required'
	}
	
	if(!values.sortorder) {
		errors.sortorder = 'Required'
	}

	if(!values.parent) {
		errors.parent = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'category',
	validate
})(CategoryEdit);