import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getBrands, getHub } from '../../actions/brand';
import { getCategories, getItems } from '../../actions/hub';
import _ from 'lodash';
import TimeAgoToday from '../../components/chrome/timeago';
import { imageResize } from '../../helpers/s3';
import moment from 'moment';

const HubsItems = props => {
	
	const { brand_id } = useParams();
	
	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	const hub = useSelector(state => state.hub);
	
	const [loading, setLoading] = useState(true);
	
	useEffect(() => {
		console.log('useEffect', 'BrandsHubItems')
		dispatch(getBrands());
	}, []);
	
	useEffect(() => {
		if(brand_id){
			dispatch(getCategories(brand_id));
		}
	}, [brand_id]);
	
	useEffect(() => {
		
		dispatch(getItems()).then(() => {
			setLoading(false);
		})
		
	}, [brand]);	
	
	if(!brand.brands){
		
		return (
			<Loading />
		);
	}else if(brand_id && !hub.categories){
			
		return (
			<Loading />
		);
	}
	
	const onRowClick = (id) => {
		
		let open = `/items/item/${id}`;
	
		if(brand_id){
			open = `/items/${brand_id}/item/${id}`;
		}
		
		props.history.push(open);
	}
	
	const List = () => {
				
		if(!hub.items){
			
			return (
				<Loading />
			);
			
		}else{
									
			const Items = () => {
				
				if(loading){
					return (
						<Loading />
					);
				}
				
				let items = [];
		
				if(brand_id){
					
					if(hub.categories[brand_id]){
						
						let categories = [];
						
						_.forEach(hub.categories[brand_id], (category) => {
							categories.push(category.id);
						});
												
						_.forEach(hub.items, (item) => {
							
							if(item.assigned[brand_id] && _.intersection(categories, item.assigned[brand_id]).length > 0){
								items.push(item);
							}
						});
					}				
					
				}else{
					items = hub.items;
				}

				if(_.isEmpty(items)){
					
					return (
						<EmptySection
							title="No Items"
							icon="fal fa-photo-video"
							description="There are currently no items to display for this brand!"
							fullHeight={true}
						/>
					);
					
				}else{
				
					items = _.sortBy(items, ['created']).reverse();
					
					return (
						<div className="table-responsive">
							<Table 
								className="data-table row-click" 
								id="table"
								sortable={[
								    'created',
								    'title',
								    'brands_count'
								]}
								defaultSort={{column: 'created', direction: 'desc'}}
								hideFilterInput
								itemsPerPage={30} 
								pageButtonLimit={5}
							>
								<Thead>
									<Th column="created" className="sorting">Created</Th>
									<Th column="title" className="sorting">Title</Th>
									<Th column="brands_count" className="sorting">Assigned Brands</Th>
								</Thead>
		       
						        {_.map(items, item => {
				
									return (
										<Tr key={item.id} 
										onClick={() => onRowClick(item.id) } 
										className="clickabale">
								             <Td column="created" value={item.created}>
								                {moment(item.created).format('DD/MM/YYYY HH:mm')}
								            </Td>
								            <Td column="title" value={item.title}>
								                <strong>{item.title}</strong>
								            </Td>
								            <Td column="brands_count">
								                {_.size(item.assigned)}
								            </Td>
								        </Tr>
								    );
								})}
							</Table>
						</div>						
					);
				}			
			}				
		
			return(
				<React.Fragment>
					{_.size(brand.brands) > 1 && 
						<div className="row">
							<div className="col col-12 col-sm-12 col-md-12">
								<FormField
									name="brand"
									placeholder="View as a specific brand"
									className="mb-30"
									type="suggest"
									noControl={true}
									simpleValue
									options={[...[{
										label: 'All brands',
										value: false
									}], ..._.map(_.sortBy(brand.brands, ['name']), (brand, key) => {
										return(
											{
												label: brand.name,
												value: brand.id
											}
										);
									})]}
									selected={brand_id ? brand_id : false}
									onChangeFunc={(selected) => {									
										props.history.push(`/items/${selected.value ? selected.value : ''}`);
									}}
								/>	
							</div>
						</div>
					}
					<Items />
				</React.Fragment>		
			);	
		}
	}
	
	let newUrl = `/items/item`;
	
	if(brand_id){
		newUrl = `/items/${brand_id}/item?brand=${brand_id}`;
	}
	
	return (
		<div>
		    <div id="page-header">
				<h2>
					Items
				</h2>
				
				<div id="actions">
					<Button
						label="New Item"
						url={newUrl}
					/>					
				</div>
			</div>
			
			<div className="panel">
	    		<div className="content">
	    			<List />
	    		</div>
			</div>	
		</div>
	);
}

export default HubsItems;