import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getBrands } from '../../actions/brand';
import { getItem } from '../../actions/hub';
import { getAlert } from '../../actions/alert';
import { sendNotification } from '../../actions/notification';
import _ from 'lodash';
import { getGroups } from '../../actions/group';

const NotificationCompose = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;

	const brand = useSelector(state => state.brand);
	const group = useSelector(state => state.group);
	const hub = useSelector(state => state.hub);
	const alert = useSelector(state => state.alert);
	
	const [selectedBrand, setSelectedBrand] = useState(query.get('brand') ? [parseFloat(query.get('brand'))] : []);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [selectedItem, setSelectedItem] = useState(query.get('item'));
	const [selectedAlert, setSelectedAlert] = useState(query.get('alert'));
	const [format, setFormat] = useState('both');
	
	useEffect(() => {
		dispatch(getBrands());
		dispatch(getGroups());
		
		if(selectedItem){
			dispatch(getItem(query.get('item')));
		}else if(selectedAlert){
			dispatch(getAlert(query.get('alert')));
		} 
		
	}, []);	
	
	useEffect(() => {
		
		if(brand.brands){
			
			if(_.size(brand.brands) == 1){
				setSelectedBrand(Object.values(brand.brands)[0].id);
			}
		}
		
	}, [brand]);	
	
	useEffect(() => {
		
		if(selectedItem && hub.items){
			
			const item = hub.items[selectedItem];
			const data = JSON.parse(item.data);

			setSelectedBrand(_.map(hub.items[selectedItem].assigned, (value, key) => {
				return parseFloat(key);
			}));
						
			const email_subject = 'New item added!';
			const email_message = `A new item entitled "${hub.items[selectedItem].title}" has been added and is available to view on the app now!`;
			const push_message = `A new item entitled "${hub.items[selectedItem].title}" is now available!`;
			
			props.change('email_subject', email_subject);
			props.change('email_message', email_message);
			props.change('push_message', push_message);
			
		}else if(selectedAlert && alert.alerts){
			
			const item = alert.alerts[selectedAlert];
			
			setSelectedBrand(alert.alerts[selectedAlert].brands);
			
			const email_subject = 'New alert!';
			const email_message = `A new alert entitled "${alert.alerts[selectedAlert].title}" has been assigned to your account!`;
			const push_message = `A new alert entitled "${alert.alerts[selectedAlert].title}" has been assigned!`;
			
			props.change('email_subject', email_subject);
			props.change('email_message', email_message);
			props.change('push_message', push_message);
		} 
		
	}, [hub, alert]);	
	
	if((selectedItem && (!hub.items || !hub.items[selectedItem])) || (selectedAlert && (!alert.alerts || !alert.alerts[selectedAlert])) || !brand.brands || !group.groups){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {
		
		if(!selectedBrand || selectedBrand.length == 0){
			return false;
		}
	
		let posting = {
			brands: selectedBrand,
			groups: selectedGroup,
			type: 'standard'
		}
			
		if(selectedItem){
			posting.type = 'item';
			posting.item_id = selectedItem;
		}else if(selectedAlert){
			posting.type = 'alert';
			posting.alert_id = selectedAlert;
		}
		
		if(format == 'email' || format == 'both'){
			posting.email = {
				subject: values.email_subject,
				message: values.email_message,
			}
		}
	
		if(format == 'push' || format == 'both'){
			posting.push = {
				message: values.push_message,
			}
		}
				
		dispatch(sendNotification(
			posting
		)).then((id) => {
			props.history.push(props.parentPath);
		});
	}
	
	let brands = [];
	
	if(selectedItem){

		_.forEach(Object.keys(hub.items[selectedItem].assigned), (value) => {
						
			let match = _.find(brand.brands, {id: parseFloat(value) });
			
			if(match){
				brands.push(match);
			}
		});
		
	}else if(selectedAlert){
		
		_.forEach(alert.alerts[selectedAlert].brands, (value) => {
						
			let match = _.find(brand.brands, {id: value });
			
			if(match){
				brands.push(match);
			}
		});
		
	}else{
		brands = brand.brands;
	}
	
	
	
	const BrandList = () => {
		
		const Groups = ({ id }) => {
						
			if(group.groups[id] && _.size(group.groups[id]) > 0){
									
				return (
					<FormField
						type="suggest"
						name={`group[${id}]`}
						placeholder="Selected groups only?"
						isMulti
						noControl={true}
						options={_.map(group.groups[id] , (group, key) => {
							return ({
								value: group.id,
								label: group.name
							})
						})}
						selected={selectedGroup[id] ? selectedGroup[id] : false}
						onChangeFunc={(selected) => { 
							let current = selectedGroup;
							current[id] = _.map(selected, (item) => {
								return item.value;
							})

							setSelectedGroup(_.clone(current));
						}}
					/>
				)
			}else{
				return null;
			}
		}
	
		if(_.size(brand.brands) == 1){
			
			if(group.groups[Object.values(brand.brands)[0].id] && _.size(group.groups[Object.values(brand.brands)[0].id]) > 0){
				
				return (
					<React.Fragment>
						<label className="label-main mt-40">Groups</label>
						<Groups id={Object.values(brand.brands)[0].id} />			
					</React.Fragment>
				)
			}
			
		}else{
			
			return (
				
				<React.Fragment>
				
					<label className="label-main mt-40">Brands</label>
					
					{_.map(brand.brands, (brand, key) => {
											
						return (
							<div className="row">
								<div className="col col-12 col-sm-4 col-md-3">
																	
									<FormField
										name={`brand[${brand.id}]`}
										type="checkbox"
										options={[{
											value: '1',
											label: brand.name
										}]}
										checked={_.indexOf(selectedBrand, brand.id) >= 0 ? true : false}
										onChangeFunc={(event) => { 
											
											let current = selectedBrand;
																											
											if(event.target.checked){
												current.push(brand.id);
											}else{
												current = _.pull(current, brand.id);
											}
							
											setSelectedBrand(_.uniq(current));
										}}
									/>						
								</div>
								<div className="col col-12 col-sm-8 col-md-9">
									{_.indexOf(selectedBrand, brand.id) >= 0 && 
										<Groups id={brand.id} />		
									}						
								</div>
							</div>
						)
					})}
					
					{selectedItem && 
						<div className="mb-20" style={{ marginTop: '-20px' }}>
							<small>
								Please note: The brands available above are those with access to the hub item.
							</small>
						</div>
					}
				</React.Fragment>
			);
		}
		
		return null;
	}
	
	
	
	
	return (
		<div>
			<div className="header">
				<h3>
					Send Notification
				</h3>
			</div>

			<form onSubmit={handleSubmit(Submit)}>			
												
				{selectedItem && 
					
					<div className="mb-20">
						<label className="label-main">Selected Hub Item</label>
						<div>
							{hub.items[selectedItem].title}
						</div>
					</div>
					
				}
				
				{selectedAlert && 
					
					<div className="mb-20">
						<label className="label-main">Selected Alert</label>
						<div>
							{alert.alerts[selectedAlert].title}
						</div>
					</div>
					
				}
	
				<BrandList />			
				
				{selectedAlert && 
					<div className="mb-20" style={{ marginTop: '-20px' }}>
						<small>
							Please note: The brands available above are those where this alert is assigned.
						</small>
					</div>
				}
				
				<Field
					label="Send By"
					name="format"
					type="radio"
					noControl={true}
					className="inline autoWidth"
					options={[
						{
							value: 'email',
							label: 'Email'
						},
						{
							value: 'push',
							label: "Push Notification"
						},
						{
							value: 'both',
							label: "Both"
						}
					]}
					checked={format}
					component={FormField}
					onChangeFunc={(e) => { setFormat(e.target.value) }}
				/>
				
				{(format == 'email' || format == 'both') &&
					
					<React.Fragment>
					
						<legend>Email</legend>
						
						<Field 
							name="email_subject" 
							type="text" 
							label="Subject" 
							component={FormField} 
						/>
					
						<Field 
							name="email_message" 
							type="textarea" 
							label="Message" 
							component={FormField} 
						/>
						
					</React.Fragment>
				}
				
				{(format == 'push' || format == 'both') &&
					
					<React.Fragment>
					
						<legend>Push Notification</legend>
			
						<Field 
							name="push_message" 
							type="textarea" 
							label="Message" 
							component={FormField} 
						/>
						
					</React.Fragment>
				}
				
				<Button
					{...props}
					label="Send"
				/>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.email_subject) {
		errors.email_subject = 'Required'
	}
	
	if(!values.email_message) {
		errors.email_message = 'Required'
	}
	
	if(!values.push_message) {
		errors.push_message = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'compose',
	validate
})(NotificationCompose);