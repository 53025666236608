import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getAsset, deleteAsset } from '../../actions/asset';
import { getBrands } from '../../actions/brand';
import { getItems } from '../../actions/hub';
import _ from 'lodash';
import moment from 'moment';
import { imageResize, imageUrl } from '../../helpers/s3';
import FileViewer from 'react-file-viewer';
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const AssetView = props => {
	
	const { id } = useParams();
		
	if(!id){
		props.history.push(props.parentPath);
	}
	
	const dispatch = useDispatch();
	const asset = useSelector(state => state.asset);
	const brand = useSelector(state => state.brand);
	const hub = useSelector(state => state.hub);
	
	const [assigned, setAssigned] = useState(false);
	const [assignedOther, setAssignedOther] = useState(false);

	let details = false;

	useEffect(() => {
		console.log('useEffect', 'AssetView')
		dispatch(getAsset(id));
		dispatch(getBrands());
		dispatch(getItems());
	}, [dispatch]);
	
	useEffect(() => {
		if(!details.id){
			//props.history.push(props.parentPath);
		}
	}, [details]);
	
	if(!asset.files || !asset.files[id] || !brand.brands || !hub.items){

		return (
			<Loading />
		);
	}
		
	details = asset.files[id];
	
	let type = details.type.charAt(0).toUpperCase() + details.type.slice(1)
	
	switch(details.type){
		
		case 'pdf':
			type = 'PDF';
			break;
	}
	
	const File = () => {
		
		if(details.type === 'image'){

			return (
				<div id="file-view">
					<img src={imageResize(details.filename, 1500)} />
				</div>
			);
			
		}else if(details.type === 'video'){
			
			let config = { 
				file: { 
					attributes: {
						controlsList: 'nodownload',
						crossOrigin: 'true'
					}
				}
			}
			
			if(details.subtitles && details.subtitles !== null && details.subtitles !== ''){
				config.file.tracks = [
					{
						kind: 'subtitles', 
						src: imageUrl(details.subtitles),
						srcLang: 'en', 
						default: true
					}
				]
			}
				
			return(
				<div className="video-container">
					<ReactPlayer 
						url={imageUrl(details.filename)} 
						controls={true}
						width='100%'
						height='100%'
						className='react-player'
						config={config}
					/>
				</div>
			);
						
		}else{
			
			const mime = require('mime-types')
					
			return (
				<div id="file-view">
					<FileViewer
				        fileType={mime.extension(details.mime)}
				        filePath={imageUrl(details.filename)}
				    />
				</div>
			);
		} 
	}	
	
	const Assigned = ({ type }) => {
		
		let ret = '';
		let others = 0;
		
		switch(type){
			
			case 'brands':
				
				if(details.assigned.brands.length > 0){
					
					const ret = _.map(details.assigned.brands, (brand, key) => {
																									
						if(brand.brands && brand.brands[brand.id]){
							
							setAssigned(true);
							
							return (
								<li key={key}>
									<Link to={`/brands/${brand.id}`}>
										{brand.name}
									</Link>
								</li>
							);
						}else{
							++others;
							setAssignedOther(true);
							return null;
						}
					})
													
					return (
						<div className="mb-15">
							<label className="label-main">Assigned Brands</label>
							<div>
								<ul>
									{ret}
								</ul>
								{others > 0 && 
									<div style={{ marginTop: '5px', fontSize: '11px' }}>
										+ {others} other{others > 1 ? 's' : ''} which you do not have access to.
									</div>
								}
							</div>
						</div>
					);	
				}	
				break;
				
			case 'categories':
							
				if(details.assigned.categories.length > 0){
					
					const ret = _.map(details.assigned.categories, (item, key) => {
																									
						if(brand.brands && brand.brands[item.brand_id]){
							
							setAssigned(true);
							
							return (
								<li key={key}>
									<Link to={`/hubs/${item.brand_id}/${item.id}`}>
										{item.title}
									</Link>
								</li>
							);
						}else{
							++others;
							setAssignedOther(true);
							return null;
						}
					})
																	
					return (
						<div className="mb-15">
							<label className="label-main">Assigned Categories</label>
							<div>
								<ul>
									{ret}
								</ul>
								{others > 0 && 
									<div style={{ marginTop: '5px', fontSize: '11px' }}>
										+ {others} other{others > 1 ? 's' : ''} which you do not have access to.
									</div>
								}
							</div>
						</div>
					);	
				}	
				break;
				
			case 'items':
							
				if(details.assigned.items.length > 0){
					
					const ret = _.map(details.assigned.items, (item, key) => {
																									
						if(hub.items && hub.items[item.id]){
							
							setAssigned(true);
							
							return (
								<li key={key}>
									<Link to={`${props.parentPath}/item/${item.id}`}>
										{item.title}
									</Link>
								</li>
							);
						}else{
							++others;
							setAssignedOther(true);
							return null;
						}
					})
													
					return (
						<div className="mb-15">
							<label className="label-main">Assigned Items</label>
							<div>
								<ul>
									{ret}
								</ul>
								{others > 0 && 
									<div style={{ marginTop: '5px', fontSize: '11px' }}>
										+ {others} other{others > 1 ? 's' : ''} which you do not have access to.
									</div>
								}
							</div>
						</div>
					);	
				}	
				break;
		}

		return ret;
	}
		
	return (
		<div>
			<div className="header">
				<h3>
					{details.title}
				</h3>
			</div>

			<div className="row">
					
				<div className="col col-12 col-sm-8 col-md-9">
					<File />
				</div>
				
				<div className="col col-12 col-sm-4 col-md-3">
					<div className="mb-15">
						<label className="label-main">Type</label>
						<div>
							{type}
						</div>
					</div>
					
					<Assigned type="brands" />
					<Assigned type="categories" />
					<Assigned type="items" />
					
					<div className="cta">
						{!assignedOther && 
							<Button
								label="Edit"
								url={`${props.parentPath}/file/${details.id}/edit`}
							/>
						}
						
						{!assignedOther && 
							<Button
								label="Delete"
								type="button"
								className="warning"
								onClick={() => {
									
									MySwal.fire({
										icon: 'question',
										title: 'Delete',
										text: 'Are you sure you wish to delete this file?',
										showCancelButton: true,
										customClass: {
											confirmButton: 'btn warning mr-10',
											cancelButton: 'btn'
										},
										buttonsStyling: false
									}).then((result) => {
										
										if(result.isConfirmed){
											dispatch(deleteAsset(
												id
											)).then((id) => {
												props.history.push(props.parentPath);
											});
										}
									});
									
									
									
									//if(confirm('Are you sure you wish to delete this file?')){
				
										console.log('delete')
										
										
										/*this.props.assetDelete(this.state.id, () => {
								
											this.props.assetsFilesFetch();
											
											// SHOW NOTIFICATION	
											this.props.showNotification(
												'success',
												'File deleted',
												`The file has been deleted`
											);
											
											this.props.history.push(this.props.parentPath);
										});*/
									//}
								}}
							/>	
						}
		    		</div>
		    	</div>
			</div>
		</div>
	);
}

export default AssetView;