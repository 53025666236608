import { api } from '../helpers/api';
import { userConstants } from '../constants/user';

export const getUsers = () => async dispatch => {
    
    try{
        const request = await api().get('/user/users');
        
        dispatch({
			type: userConstants.GET_USERS,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const getUser = (id) => async dispatch => {
    
    try{
        const request = await api().get(`/user/users/${id}`);
        
        dispatch({
			type: userConstants.GET_USER,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const saveUserGroups = (id, values) => async dispatch => {

	try{
		const request = await api().post(`/user/groups/${id}`, values);
		
		dispatch({
			type: userConstants.SAVE_GROUPS,
			payload: request
		});
		
		return id;
	}
	catch(error){
		 console.log('error', error)
	}
}
