import React, { useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Nav from './nav';

const Layout = props => {

	
	const prev = useRef(props);
	
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
					
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		
		if (Object.keys(changedProps).length > 0) {
			console.log('Changed props:', changedProps);
		}
		
		prev.current = props;
	});
	
	
	
  return (
		<React.Fragment>
			<header>
				<div className="inner">
					<Link to="/" className="logo">
						<img src={`/images/logo.png?v=${process.env.CACHE_DIR}`} alt="Hapori Wellbeing" />
					</Link>					
					<Nav />
				</div>
			</header>
			<div id="content" className="container-fluid">
				{props.children}					
			</div>
		</React.Fragment>
	);
}

export default Layout;