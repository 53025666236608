import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getBrands } from '../../actions/brand';
import { getGroups } from '../../actions/group';
import _ from 'lodash';

const Groups = props => {
	
	const { brand_id } = useParams();
	
	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	const group = useSelector(state => state.group);
	
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		console.log('useEffect', 'BrandsView')
		dispatch(getBrands());
	}, []);
	
	useEffect(() => {
		if(brand_id){
			dispatch(getGroups(brand_id));
		}
	}, [brand_id]);
	
	useEffect(() => {
		
		if(!_.isEmpty(brand.brands)){
						
			if(!brand_id){
				props.history.push(`/groups/${brand.brands[Object.keys(brand.brands)[0]].id}`);
			}else{
				dispatch(getGroups(brand_id)).then(() => {
					setLoading(false);
				})
			};
		}
		
	}, [brand]);	
		
	if(!brand.brands || !group.groups){
		
		return (
			<Loading />
		);
	}
	
	const onRowClick = (id) => {
		props.history.push(`/groups/${brand_id}/group/${id}/edit`);
	}
	
	const Groups = () => {
				
		if(!brand_id || !group.groups){
			
			return (
				<Loading />
			);
			
		}else{
									
			const Items = () => {
				
				if(loading){
					return (
						<Loading />
					);
				}
				
				let items = [];
				
				if(_.isEmpty(group.groups[brand_id])){
					
					return (
						<EmptySection
							title="No Groups"
							icon="fal fa-tag"
							description={`There are currently no groups to display for this brand!`}
							fullHeight={true}
						/>
					);
					
				}else{
									
					return (
						<div className="table-responsive">
							<Table 
								className="data-table row-click" 
								id="table"
								sortable={[
									'name',
									'items',
									'users'
								]}
								defaultSort={{column: 'name', direction: 'asc'}}
								hideFilterInput
								itemsPerPage={30} 
								pageButtonLimit={5}
							>
								<Thead>
									<Th column="name" className="sorting">Name</Th>
								</Thead>
						
								{_.map(group.groups[brand_id], item => {
						
									return (
										<Tr key={item.id} 
											onClick={() => onRowClick(item.id) } 
											className="clickabale"
										>
											<Td column="name" value={item.title}>
												<strong>{item.name}</strong>
											</Td>
										</Tr>
									);
								})}
							</Table>
						</div>
					);
					
				}			
			}	
			
			return(
				<React.Fragment>
					{_.size(brand.brands) > 1 && 
						<FormField
							name="brand"
							placeholder="View as a specific brand"
							className="mb-30"
							type="suggest"
							noControl={true}
							simpleValue
							sortorder={true}
							options={_.map(brand.brands, (brand, key) => {
								return(
									{
										label: brand.name,
										value: brand.id
									}
								);
							})}
							selected={brand_id}
							onChangeFunc={(selected) => {									
								props.history.push(`/groups/${selected.value}`);
							}}
						/>	
					}
					<Items />
				</React.Fragment>		
			);	
		}
	}
	
	return (
		<div>
			<div id="page-header">
				<h2>Groups</h2>
				
				<div id="actions">
					<Button
						label="New Group"
						url={`/groups/${brand_id}/group?brand=${brand_id}`}
					/>				
				</div>
			</div>
			
			<div className="panel">
				<div className="content">
					<Groups />
				</div>
			</div>	
		</div>
	);
}

export default Groups;