import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getBrands, saveCode, deleteCode } from '../../actions/brand';
import { getGroups } from '../../actions/group';
import _ from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
const CodeEdit = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { brand_id, id } = useParams();

	const [selectedGroups, setSelectedGroups] = useState([]);
	const brand = useSelector(state => state.brand);
	const group = useSelector(state => state.group);
			
	useEffect(() => {
		dispatch(getGroups(brand_id));
		dispatch(getBrands());
	}, []);	
	
	useEffect(() => {
		
		if(id && brand.brands && brand.brands[brand_id]){
			const thisBrand = brand.brands[brand_id];
			const thisCode = _.find(thisBrand.codes, { id: parseFloat(id) });

			props.change('code', thisCode.code);
			setSelectedGroups(_.map(thisCode.groups, (value) => value.group_id));
		}
	}, [brand]);	

	if(!group.groups || (id && (!brand.brands || !brand.brands[brand_id]))){
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {
		
		let posting = {
			brand: brand_id, 
			code: values.code,
			groups: selectedGroups
		}
				
		dispatch(saveCode(
			id ? id : false, 
			posting
		)).then((ret) => {
			
			if(ret == 'dupe'){
				alert('This code is already in use, please choose another!')
			}else{
				props.history.push(props.parentPath);
			}
		});
	}

	return (
		<div>
			<div className="header">
				<h3>
					{id ? 'Edit Code' : 'Add Code'}
				</h3>
			</div>

			<form onSubmit={handleSubmit(Submit)}>			
								
				<Field 
					name="code" 
					type="number"
					label="6 Digit Code" 
					component={FormField} 
				/>		
				
				{!_.isEmpty(group.groups[brand_id]) && 
					<FormField
						name="groups"
						label="Assign to group(s)"
						placeholder="Select groups(s) or leave blank"
						type="suggest"
						noControl={true}
						sortorder={true}
						isMulti
						options={_.map(group.groups[brand_id], (value, key) => {
							return(
								{
									label: value.name,
									value: value.id
								}
							);
						})}
						selected={selectedGroups}
						onChangeFunc={(selected) => { 
							setSelectedGroups(_.map(selected, (item) => {
								return item.value;
							}));
						}}
					/>
				}
				
				<div className="cta">
					<Button
						{...props}
						label="Save Code"
					/>
					
					{id && 
						<Button
							label="Delete"
							type="button"
							className="warning"
							onClick={() => {
								
								MySwal.fire({
									icon: 'question',
									title: 'Delete',
									text: 'Are you sure you wish to delete this code?',
									showCancelButton: true,
									customClass: {
										confirmButton: 'btn warning mr-10',
										cancelButton: 'btn'
									},
									buttonsStyling: false
								}).then((result) => {
									
									if(result.isConfirmed){
										dispatch(deleteCode(
											id
										)).then((id) => {
											props.history.push(props.parentPath);
										});
									}
								});
							}}
						/>
					}
				</div>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.code) {
		errors.code = 'Required'
	}else if(values.code.length !== 6){
		errors.code = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'code',
	validate
})(CodeEdit);