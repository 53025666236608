import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { getBrands, getHub } from '../../actions/brand';
import { getCategories, getItems } from '../../actions/hub';
import _ from 'lodash';
import TimeAgoToday from '../../components/chrome/timeago';
import { imageResize } from '../../helpers/s3';

const Hubs = props => {
	
	const { brand_id, category_id } = useParams();
	
	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	const hub = useSelector(state => state.hub);
	
	const [loading, setLoading] = useState(true);
	//const [selectedBrand, setSelectedBrand] = useState(query.get('brand'));
	//const [selectedCategory, setSelectedCategory] = useState(query.get('category'));
	
	useEffect(() => {
		console.log('useEffect', 'BrandsView')
		dispatch(getBrands());
	}, []);
	
	useEffect(() => {
		if(brand_id){
			dispatch(getCategories(brand_id));
		}
	}, [brand_id]);
	
	useEffect(() => {
		
		if(!_.isEmpty(brand.brands)){
						
			if(!brand_id){
				props.history.push(`/hubs/${brand.brands[Object.keys(brand.brands)[0]].id}`);
			}else{
				dispatch(getItems(brand_id)).then(() => {
					setLoading(false);
				})
			};
		}
		
	}, [brand]);	
	
	if(!brand.brands || !hub.categories || !hub.categories[brand_id]){
		
		return (
			<Loading />
		);
	}
	
	const Hub = () => {
				
		if(_.isEmpty(brand.brands)){ 
			return (
				<EmptySection
					title="No Hubs"
					icon="fal fa-photo-video"
					description="There are currently no brands on the system, so no hubs are available!"
					fullHeight={true}
				/>
			);
			
		}else if(!brand_id || !hub.categories){
			
			return (
				<Loading />
			);
			
		}else{
									
			const Items = () => {
				
				if(loading){
					return (
						<Loading />
					);
				}
				
				let categories = _.sortBy(_.filter(hub.categories[brand_id], { parent_id: category_id ? parseFloat(category_id) : null }), ['sortorder']);
				let items = [];
				
				if(category_id){
					_.forEach(hub.items, (item) => {
						
						if(item.cat_id == category_id){
							items.push(item);
						}
					});
				}
				
				if(_.isEmpty(categories) && _.isEmpty(items)){
					
					return (
						<EmptySection
							title="No Categories or Items"
							icon="fal fa-photo-video"
							description={`There are currently no categories or items to display ${category_id ? 'within this category' : 'for this brand!'}`}
							fullHeight={true}
						/>
					);
					
				}else{
				
					items = _.sortBy(items, ['created']).reverse();
					
					return (
						<div id="assets">
							{!_.isEmpty(categories) && 	
								<div className="row">
									<div className="masonry">
										{_.map(categories, (item, key) => {
		
											return (
												<a href="javascript:;" onClick={() => { 
													props.history.push(`/hubs/${brand_id}/${item.id}`);
												}} key={key} className="brick hover">
													<div className="thumbnail image">
														<div style={{ backgroundImage: `url(${imageResize(item.asset_image, 500)})`}} />
													</div>
													<div>
														<i className="far fa-folder"></i> {item.title}
													</div>
												</a>
											);
										})}	
									</div>	
								</div>	
							}
								
							{items.length > 0 && 				
								<div className="row">
									<div className="masonry">
										{_.map(items, (item, key) => {
		
											return (
												<Link to={`${props.parentPath}/item/${item.id}`} key={key} className="brick hover">
													<div className="thumbnail image">
														<div style={{ backgroundImage: `url(${imageResize(item.asset_image, 500)})`}} />
													</div>
													<div>
														{item.title}
													</div>
													<small>
														<TimeAgoToday 
															date={item.created} 
														/>
													</small>
												</Link>
											);
										})}	
									</div>		
								</div>
							}
						</div>
					);
					
				}			
			}	
			
			const organiseCategories = () => {
		
				let ret = [{
					value: false,
					label: 'None - Root'
				}];
				
				const loopCategories = (level, id) => {
									
					let options = _.sortBy(_.filter(hub.categories[brand_id], { parent_id: id }), ['sortorder']);
					
					_.forEach(options, (child, key) => {
						
						let label = child.title;
						
						if(level > 0){
							label = '- '.repeat(level) + label;
						}
						
						ret.push({
							value: child.id,
							label: label
						});
						
						options[key].children = loopCategories(level+1, child.id);
					});
					
					return options;
				}
				
				loopCategories(0, null);
				
				return ret;
			}	
		
			return(
				<React.Fragment>
					<div className="row">
						{_.size(brand.brands) > 1 && 
							<div className="col col-12 col-sm-6 col-md-6">
								<FormField
									name="brand"
									placeholder="View as a specific brand"
									className="mb-30"
									type="suggest"
									noControl={true}
									simpleValue
									sortorder={true}
									options={_.map(brand.brands, (brand, key) => {
										return(
											{
												label: brand.name,
												value: brand.id
											}
										);
									})}
									selected={brand_id}
									onChangeFunc={(selected) => {									
										props.history.push(`/hubs/${selected.value}`);
									}}
								/>	
							</div>
						}
						<div className={`col col-12 ${_.size(brand.brands) > 1 ? 'col-sm-6 col-md-6' : ''}`}>
							<FormField
								type="suggest"
								name="category"
								className="mb-30"
								noControl={true}
								simpleValue
								options={organiseCategories()}
								selected={category_id}
								onChangeFunc={(selected) => { 
									props.history.push(`/hubs/${brand_id}/${selected.value}`);
								}}
							/>
						</div>
					</div>
					<Items />
				</React.Fragment>		
			);	
		}
	}
	
	let actions = (
		
		<Button
			label="New Category"
			url={`/hubs/${brand_id}/category?brand=${brand_id}&parent=${category_id}`}
		/>			
	);
	
	if(brand_id && category_id){
		
		actions = (
			<Button
				label="New Item"
				url={`/hubs/${brand_id}/item?brand=${brand_id}&category=${category_id}`}
				split={[
					{
						label: "New Sub Category",
						url: `/hubs/${brand_id}/category?brand=${brand_id}&parent=${category_id}`
					},
					{
						label: "Edit Category",
						url: `/hubs/${brand_id}/category/${category_id}/edit`
					}
				]}
			/>
		);
	}
	
	let title = 'Hubs';
	let back = '';
	
	if(category_id){
		let category = _.find(hub.categories[brand_id], { id: category_id });
		
		if(category){
			title = category.title;
			
			back = (
				<a href="javascript:;" onClick={() => { 
					props.history.push(`/hubs/${brand_id}/${category.parent_id}`);
				}} >
					<i className="back far fa-chevron-left"></i>
				</a>
			);
		}
	}
	
	return (
		<div>
		    <div id="page-header">
				<h2>
					{back}{title}
				</h2>
				
				<div id="actions">
					{actions}					
				</div>
			</div>
			
			<div className="panel">
	    		<div className="content">
	    			<Hub />
	    		</div>
			</div>	
		</div>
	);
}

export default Hubs;