import React, { useState } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const Field = props => {
	
	const [focusedInput, setFocusedInput] = useState(null);
		
	const {
		input,
		label,
		type,
		meta,
		placeholder
	} = props;
	
	let className = `${meta && meta.touched && meta.error ? 'is-invalid' : ''} ${props.className ? props.className : ''}`
	
	let field = '';
	let prepend = '';
	let append = '';
	
	const onInputChange = (event) => {

		// ALWAYS CALL DEFAULT ONCHANGE HERE
        if(props.input){
	        props.input.onChange(event);
		}
		
        // THEN CALL THE CUSTOM ONCHANGE IF SET
        if(props.onChangeFunc){
	        props.onChangeFunc(event);
		}
	}
	
	switch(type){
		
		case 'suggest':
		
			className += ' form-control-plain';
			
			let options = props.options;
			let searchable = true;			
			
			if(props.searchable){
				searchable = props.searchable;
			}
							
			if(props.sortorder){
									
				options.sort(function(a,b){
					
					let itemA = a.label.toLowerCase();
					let itemB = b.label.toLowerCase();
									
					if(itemA < itemB) return -1;
				    if(itemA > itemB) return 1;
				    return 0;
				});
							
				switch(props.sortorder){
					
					case 'desc':
						options.reverse();
						break;
				}
			}
			
			const valueRenderer = (value) => {
				
				if(props.valueRenderer){
					return props.valueRenderer(value);
				}else{
					return value.label;
				}
			}
			
			let selected = [];
			
			if(typeof props.selected !== 'undefined'){
												
				_.forEach(options, (option) => {
																
					if(Array.isArray(props.selected)){
												
						if(props.selected.includes(option.value)){
							selected.push(option);
						}
						
					}else if(props.selected == option.value){
						selected = option;
					}
				});
			}
					
			field = (
				<Select
		            {...props}
					className={`${className} select-suggest`}					
					placeholder={placeholder}
					options={options}
					value={selected}
					openOnFocus={true}
					searchable={searchable}
					onChange={onInputChange}
					valueRenderer={valueRenderer}
				/>
			);
			break;
			
		case 'file':
			className += ' form-control';
			
			field = (
				<input 
					type={type} 
					className={className}
					onChange={onInputChange}
				/>
			);
			break;
			
		case 'radio':
			field = (
				<div className={className}>
					{_.map(props.options, option => {
					
						return (
							<div className="form-radio" key={option.value}>
								<label className={`form-check-label ${props.checked == option.value ? 'checked' : ''}`}>
									<input
										{...props.input}
										type="radio"
										className="form-check-input"
										value={option.value}
										checked={props.checked === option.value}
										onChange={onInputChange}
									/>
									{option.label}
								</label>
							</div>
						);
					})}
				</div>
			);
			break;
			
		case 'textarea':
			className += ' form-control';
			
			field = (
				<textarea 
					{...input} 
					placeholder={placeholder} 
					className={className}
					onChange={onInputChange}
				>
					{typeof props.selected !== 'undefined' ? props.selected : input.value}
				</textarea>
			);
			break;
			
		case 'checkbox':
			field = (
				<div className={className}>
					{_.map(props.options, option => {

						return (
							<div className="form-check" key={option.value}>
								<label className={`form-check-label ${props.checked ? 'checked' : ''}`}>
									<input
										{...props.input}
										type="checkbox"
										className="form-check-input"
										value={option.value}
										checked={props.checked === true}
										onChange={onInputChange}
									/>
									{option.label}
								</label>
							</div>
						);
					})}
				</div>
			);
			break;
			
		case 'daterange':
			field = (
				<div className={className}>
					<DateRangePicker
				        startDateId="s_id"
				        endDateId="e_id"				        
				        displayFormat="DD/MM/YYYY"
						focusedInput={focusedInput}
				        onFocusChange={e => setFocusedInput(e)}
				        {...props}
				      />
				</div>
			);
			break;
				
		default:
			className += ' form-control';
			
			field = (
				<input 
					{...input} 
					placeholder={placeholder} 
					type={type} 
					className={className}
					onChange={onInputChange}
					value={typeof props.selected !== 'undefined' ? props.selected : input.value}
				/>
			);
			break;
	}
	
	return (
		<div className={`form-group ${props.prepend ? 'prepend' : ''} ${props.append ? 'append' : ''}`}>
			
			{label && 
				<label className="label-main">{label}</label>
			}
			
			<div className="field">
				{props.prepend && 
					<div className="prepended">
						{props.prepend}
					</div>
				}
				
				{field}
				
				{props.append && 
					<div className="appended">
						{props.append}
					</div>
				}
			</div>
			
			{/*meta && meta.touched &&
				<div className="invalid-feedback">
					{meta.error && 
						<span>{meta.error}</span>
					|| meta.warning && 
						<span>{meta.warning}</span>
					}
				</div>
			*/}
		</div>
	);
}

export default Field;