import { questionnaireConstants } from '../constants/questionnaire';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {

		case questionnaireConstants.GET_SUMMARY:

			let brands = state.brands ? state.brands : {};
			
			brands[action.id] = action.payload.data;
			
			return { 
				...state, 
				brands: brands
			};		
			
		case questionnaireConstants.GET_QUESTIONS:
		
			return { 
				...state, 
				questions: action.payload.data
			};	
			
		default:
			return state;
	}
}