import { api } from '../helpers/api';
import { assetConstants } from '../constants/asset';

export const getAssets = () => async dispatch => {
    
    try{
        const request = await api().get(`/asset/files`);
        
        dispatch({
			type: assetConstants.GET_ASSETS,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const getAsset = (id) => async dispatch => {
    
    try{
        const request = await api().get(`/asset/files/${id}`);
        
        dispatch({
			type: assetConstants.GET_ASSET,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const saveAsset = (id, values, progressUpdater) => async dispatch => {
    
    let url = '/asset/save';
    
    if(id){
	    url += `/${id}`;
	}
	
    try{
        const request = await api({ progressUpdater: progressUpdater }).post(url, values);
        
        dispatch({
			type: assetConstants.SAVE_ASSET,
			payload: request
		});
		
		return request.data.id;
    }
    catch(error){
         console.log('error', error)
    }
}

export const deleteAsset = (id) => async dispatch => {
	
    try{
        const request = await api().delete(`/asset/delete/${id}`);
        
        dispatch({
			type: assetConstants.DELETE_FILE,
			id: id
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}