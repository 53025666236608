import { notificationConstants } from '../constants/notification';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {
			
		default:
			return state;
	}
}