import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getAlert, deleteAlert } from '../../actions/alert';
import { getBrands } from '../../actions/brand';
import _ from 'lodash';
import moment from 'moment';
import { imageResize, imageUrl } from '../../helpers/s3';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const AlertView = props => {
	
	const { id } = useParams();
		
	if(!id){
		props.history.push(props.parentPath);
	}
	
	const dispatch = useDispatch();
	const alert = useSelector(state => state.alert);
	const brand = useSelector(state => state.brand);

	useEffect(() => {
		console.log('useEffect', 'AssetView')
		dispatch(getBrands());
		dispatch(getAlert(id));
	}, [dispatch]);
	
	if(!alert.alerts || !alert.alerts[id] || !brand.brands){
		
		return (
			<Loading />
		);
	}
	
	const details = alert.alerts[id];
	
	const Assigned = () => {
			
		let others = 0;
		
		const ret = _.map(details.brands, (id, key) => {
																						
			if(brand.brands && brand.brands[id]){
				
				return (
					<li key={key}>
						<Link to={`/brands/${id}`}>
							{brand.brands[id].title}
						</Link>
					</li>
				);
			}else{
				++others;
				return null;
			}
		})
											
		return (
			<React.Fragment>
				<ul>
					{ret}
				</ul>
				
				{others > 0 && 
					<div style={{ marginTop: '5px', fontSize: '11px' }}>
						+ {others} other{others > 1 ? 's' : ''} which you do not have access to.
					</div>
				}
			</React.Fragment>
		);										
	}
	
	return (
		<div>
			<div className="header">
				<h3>
					{details.title}
				</h3>
			</div>

			<div className="row">
					
				<div className="col col-12 col-sm-8 col-md-9">
				
					<div>
						<label className="label-main">Content</label>
						<div>
							{details.description}
						</div>
					</div>
					
				
					<div className="row mt-40">
					
						<div className="col col-12 col-sm-4">
							<label className="label-main">Type</label>
							<div>
								{details.type.charAt(0).toUpperCase() + details.type.slice(1)}
							</div>
						</div>
					
						<div className="col col-12 col-sm-4">
							<label className="label-main">{details.type == 'survey' ? 'Question Scout Survey ID' : 'Link'}</label>
							<div>
								<a href={`${details.type == 'survey' ? 'https://survey.haporiwellbeing.com/' : ''}${details.data}`} target="_blank">{details.data}</a>
							</div>
						</div>
						
						<div className="col col-12 col-sm-4">
							<label className="label-main">Expiry</label>
							<div>
								{details.expiry !== null ? moment(details.expiry.date).format('DD/MM/YYYY') : 'Never'}
							</div>
						</div>
					</div>
				</div>
				
				<div className="col col-12 col-sm-4 col-md-3">
					<div className="mb-15">
						<label className="label-main">Assigned</label>
						<Assigned /> 
					</div>
					
					<div className="cta">
						<Button
							label="Edit"
							url={`${props.parentPath}/alert/${details.id}/edit`}
						/>
						
						<Link 
							to={`${props.parentPath}/compose?alert=${details.id}`}
							className="btn secondary"
						>
							Notify
						</Link>
						
						<Button
							label="Delete"
							type="button"
							className="warning"
							onClick={() => {
								
								MySwal.fire({
									icon: 'question',
									title: 'Delete',
									text: 'Are you sure you wish to delete this alert?',
									showCancelButton: true,
									customClass: {
										confirmButton: 'btn warning mr-10',
										cancelButton: 'btn'
									},
									buttonsStyling: false
								}).then((result) => {
									
									if(result.isConfirmed){
										dispatch(deleteAlert(
											id
										)).then((id) => {
											props.history.push(props.parentPath);
										});
									}
								});
							}}
						/>
		    		</div>
		    	</div>
			</div>
		</div>
	);
}

export default AlertView;