import { groupConstants } from '../constants/group';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {

		case groupConstants.GET_GROUPS:
		case groupConstants.SAVE_GROUP:
		
			let groups = {};
			let brands = _.uniqBy(action.payload.data, 'brand_id');
			
			_.forEach(brands, (brand, key) => {
				groups[brand.brand_id] = _.mapKeys(_.filter(action.payload.data, { brand_id: brand.brand_id }), 'id');
			});
			
			return { 
				...state, 
				groups: groups,
				groups_all: _.mapKeys(action.payload.data, 'id')
			};

		case groupConstants.DELETE_GROUP:
		
			groups = state.groups ? state.groups : {};
			let groups_all = state.groups_all ? state.groups_all : {};
			
			if(groups[action.id]){
				delete(groups[action.id]);
			}
			
			if(groups_all[action.id]){
				delete(groups_all[action.id]);
			}
			
			return { 
				...state, 
				groups: groups,
				groups_all: groups_all
			};			
			
		default:
			return state;
	}
}