import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getUser } from '../../actions/user';
import { getBrands } from '../../actions/brand';
import { getGroups } from '../../actions/group';
import _ from 'lodash';
import moment from 'moment';
import InfoBox from '../../components/chrome/info_box';

const UserView = props => {
	
	const { id } = useParams();
		
	if(!id){
		props.history.push('/users');
	}
	
	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	const group = useSelector(state => state.group);
	const user = useSelector(state => state.user);
	
	useEffect(() => {
		console.log('useEffect', 'UserView')
		dispatch(getUser(id));
		dispatch(getBrands());
		dispatch(getGroups());
	}, [dispatch]);
	
	if(!user.users || !user.users[id]){
		
		return (
			<Loading />
		);
	}
	
	const details = user.users[id];
	
	/*
		<div id="actions">
			<Button
				label='Send Message'
				url={`/users/${details.id}/message?user=${details.id}`}
			/>
		</div>
	*/
	
	const onBrandClick = (e, brand_id) => {
		
		if(e.target.tagName.toLowerCase() === 'a'){
			e.preventDefault();
			return;
		}
		
		props.history.push(`/users/${id}/groups/${id}/${brand_id}`);
	}
	
	const Brandslist = () => {
		
		let mine = [];
		let others = 0;
		
		_.forEach(details.brands, (id, key) => {
																						
			if(brand.brands && brand.brands[id]){
				mine.push(brand.brands[id]);				
			}else{
				++others;
			}
		})
											
		return (
			<React.Fragment>
				{mine.length > 0 &&
					
					<div className="table-responsive">
						<Table 
							className="data-table row-click" 
							id="table"
							sortable={[
								'brand',
								'groups'
							]}
							defaultSort={{column: 'brand', direction: 'asc'}}
							hideFilterInput
						>
							<Thead>
								<Th column="brand" className="sorting">Brand</Th>
								<Th column="groups" className="sorting">Groups</Th>		
								<Th column="admin" className="sorting">Admin</Th>										
							</Thead>
							{_.map(mine, (brand, key) => {
								
								let groups = [];
												
								// ANY SUB GROUPS?
								if(details.groups && !_.isEmpty(details.groups) && group.groups && group.groups[brand.id]){
														
									_.forEach(group.groups[brand.id], (group) => {
										
										if(details.groups.includes(group.id)){
											groups.push(group);
										}
										
									});
								}
								
								return (
									<Tr key={`brand_${key}`}
										onClick={(e) => onBrandClick(e, brand.id) } 
										className="clickabale"
									>
										<Td column="brand">
											<Link to={`/brands/${brand.id}`}>
												{brand.name}
											</Link>
										</Td>
										<Td column="groups">
											{groups.length}
										</Td>
										<Td column="admin">
											{(details.roles == 'ROLE_SUPERADMIN' || details.admin.includes(brand.id)) ? 'Yes' : 'No'}
										</Td>
									</Tr>
								);
							})}
						</Table>
					</div>
					
				}
				
				{others > 0 && 
					<div style={{ marginTop: '5px', fontSize: '11px' }}>
						+ {others} other{others > 1 ? 's' : ''} which you do not have access to.
					</div>
				}
			</React.Fragment>
		);								
	}
	
	return (
		<div>
		    
		    <div id="page-header">
				<h2>
					{details.forename} {details.surname}
				</h2>				
			</div>
			
			<div className="row">
				<div className="col-12 col-sm-6 col-md-4">
					<div className="panel">
			    		<div className="header">
			    			<i className="fal fa-user"></i>
			    			<h3>Profile</h3>
			    			<p>
			    				 User profile and details.
			    			</p>
			    		</div>
			    		<div className="content">
    		
							<div className="mb-15">
								<label className="label-main">Email</label>
								<div>
									<a href={`mailto:${details.email}`}>{details.email}</a>
								</div>
							</div>
							
							<div className="mb-15">
								<label className="label-main">Created</label>
								<div>
									{moment(details.created.date).format('DD/MM/YYYY')}
								</div>
							</div>
							
							<div className="mb-15">
								<label className="label-main">{details.brands.length == 1 ? 'Brand' : 'Brands'}</label>
								<div>
									<Brandslist />
								</div>
							</div>
						</div>
					</div>
					
					<div className="panel">
			    		<div className="header">
			    			<i className="fal fa-user"></i>
			    			<h3>Notifications</h3>
			    			<p>
			    				 Details about how we can notify {details.forename}.
			    			</p>
			    		</div>
			    		<div className="content">
    		
							<div className="table-responsive">
								<table className="data-table">
									<thead>
										<tr>
											<th>Type</th>
											<th className="center" width="45">Email</th>
											<th className="center" width="45">Push</th>											
										</tr>											
									</thead>
									<tbody>
								        {_.map(details.notifications, (notification, key) => {
											
											return (
												<tr key={key}>
										            <td>
										                {notification.title}
										            </td>
										            <td className="center">
										                <i className={`fal fa-${notification.email == 1 ? 'check' : 'times'}`}></i>
										            </td>
										            <td className="center">
										                <i className={`fal fa-${notification.push == 1 ? 'check' : 'times'}`}></i>
										            </td>
										        </tr>
										    );
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-6 col-md-8">	
					<div className="panel">
			    		<div className="header">
			    			<i className="fal fa-info-circle"></i>
			    			<h3>Overview</h3>
			    			<p>
			    				Quickly see the state of play for {details.forename}.
			    			</p>
			    		</div>
			    		<div className="content">
								
							<div className="row pt-3">
										
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="PARQs" 
										title_sub={`Last completed ${details.parq.last ? moment(details.parq.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.parq.total}
									/>
								</div>
								
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Questionnaires" 
										title_sub={`Last completed ${details.questionnaire.last ? moment(details.questionnaire.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.questionnaire.total}
									/>
								</div>
								
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Views" 
										title_sub={`Last hub item viewed ${details.views.last ? moment(details.views.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.views.total}
										cta={{
											label: 'View History',
											url: `/history?user=${details.id}`
										}}
									/>
								</div>
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>
	);
}

export default UserView;