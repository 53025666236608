import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getAlerts } from '../../actions/alert';
import { getBrands } from '../../actions/brand';
import _ from 'lodash';
import moment from 'moment';

const Alerts = props => {
	
	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const alert = useSelector(state => state.alert);
	const brand = useSelector(state => state.brand);
	
	const [selectedBrand, setSelectedBrand] = useState(query.get('brand'));
	
	useEffect(() => {
		console.log('useEffect', 'Users')
		dispatch(getAlerts());
		dispatch(getBrands());
	}, [dispatch]);
	
	if(!alert.alerts){
		
		return (
			<Loading />
		);
	}	
	
	const onRowClick = (id) => {
		props.history.push(`/alerts/alert/${id}`);
	}
	
	const List = () => {
		
		let alerts = [];		
				
		if(selectedBrand){
			
			_.forEach(alert.alerts, (alert) => {
				
				if(alert.brands.includes(parseFloat(selectedBrand))){
					alerts.push(alert);
				}
			});
			
		}else{
			alerts = alert.alerts;
		}
			
		if(_.isEmpty(alerts)){ 
			return (
				<EmptySection
					title="No Alerts"
					icon="fal fa-bell"
					description={`${selectedBrand ? 'There are currently no alerts assigned to this brand' : 'There are currently no alerts on the system!'}`}
					fullHeight={true}
				/>
			);
			
		}else{
						
			return(
				<div className="table-responsive">
					<Table 
						className="data-table row-click" 
						id="table"
						sortable={[
						    'title',
						    'brands_count',
						    'type',
						    'status',
						    'ends'
						]}
						defaultSort={{column: 'name', direction: 'asc'}}
						hideFilterInput
						itemsPerPage={30} 
						pageButtonLimit={5}
					>
						<Thead>
							<Th column="title" className="sorting">Title</Th>
							<Th column="brands_count" className="sorting">Total Brands</Th>
							<Th column="type" className="sorting">Type</Th>
							<Th column="status" className="sorting">Status</Th>
							<Th column="ends" className="sorting">Ends</Th>
						</Thead>
       
				        {_.map(alerts, alert => {
		
							return (
								<Tr key={alert.id} 
									onClick={() => onRowClick(alert.id) } 
									className="clickabale"
								>
						            <Td column="title" value={alert.title}>
						                <strong>{alert.title}</strong>
						            </Td>
						            <Td column="brands_count">
										{alert.brands.length}
						            </Td>
						            <Td column="type">
										{alert.type.charAt(0).toUpperCase() + alert.type.slice(1)}
						            </Td>
						            <Td column="status">
										{(alert.expiry == null || moment(alert.expiry.date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) &&
											<span>Active</span>
										||
											<span>Expired</span>
										}
						            </Td>
						            <Td column="ends" value={alert.expiry !== null ? alert.expiry.date : 'Never'}>
										{alert.expiry !== null ? moment(alert.expiry.date).format('DD/MM/YYYY') : 'Never'}
						            </Td>
						        </Tr>
						    );
						})}
					</Table>
				</div>
			);	
		}
	}
	
	const Search = () => {
		
		if(!_.isEmpty(alert.alerts) && _.size(brand.brands) > 1){ 

			return(
				<FormField
					name="brand"
					placeholder="Filter alerts to a specific brand"
					className="mb-30"
					isClearable={true}
					type="suggest"
					noControl={true}
					simpleValue
					sortorder={true}
					options={_.map(brand.brands, (brand, key) => {
						return(
							{
								label: brand.name,
								value: brand.id
							}
						);
					})}
					selected={selectedBrand}
					onChangeFunc={(selected) => { 
						setSelectedBrand(selected ? selected.value : false) 
					}}
				/>
			);	
		}
		
		return null;
	}

	return (
		<div className="dashboard">
		    
		    <div id="page-header">
				<h2>
					Alerts
				</h2>
				<div id="actions">
					<Button
						label="New Alert"
						url="/alerts/alert/"
					/>
				</div>
			</div>
		    
		    <div className="panel">
	    		<div className="content">
	    			<Search />
	    			<List />
	    		</div>
	    	</div>
		    
		</div>
	);
}

export default Alerts;