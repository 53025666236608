import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import _ from 'lodash';
import { imageResize } from '../../helpers/s3';
import { getAssets, saveAsset } from '../../actions/asset';

const AssetsEdit = props => {

	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { id } = useParams();
		
	const asset = useSelector(state => state.asset);
	const [upload, setUpload] = useState(id ? false : true);
	const [uploading, setUploading] = useState(false);
	
	useEffect(() => {
		console.log('useEffect', 'AssetsEdit')
		dispatch(getAssets());
	}, []);	
	
	useEffect(() => {
		if(id){
			props.change('name', asset.files[id].title);
		}
	}, [asset]);	
	
	if(!asset.files || (id && !asset.files[id])){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {
		
		let posting = new FormData(); 
	
		if(upload){
			posting.append( 
				"file", 
				values.file[0], 
				values.file[0].name
			); 
		}
		
		posting.append('name', values.name);
														
		setUploading(0);
		
		dispatch(saveAsset(
			id ? id : false, 
			posting, 
			(percent) => {
				console.log('percent', percent)
				setUploading(percent);
			}
		)).then((id) => {
			props.history.push(props.parentPath);
		});	
	}
		
	return (
		<div>
			<div className="header">
				<h3>
					{id ? 'Edit File' : 'Add File'}
				</h3>
			</div>

			<form onSubmit={handleSubmit(Submit)}>			
								
				<Field 
					name="name" 
					type="text" 
					label="Name" 
					component={FormField} 
				/>
				
				{id && 
					<Field
						label="Replace Existing File?"
						name="replace"
						type="radio"
						noControl={true}
						className="inline autoWidth"
						options={[
							{
								value: 0,
								label: 'No, keep existing'
							},
							{
								value: 1,
								label: "Yes, I'd like to upload a new file"
							}
						]}
						checked={upload}
						component={FormField}
						onChangeFunc={(e) => { setUpload(e.target.value == '1' ? true : false) }}
					/>	
				}
				
				{upload && 
				
					<Field
						label="File"
						name="file"
						type="file"
						component={FormField}
					/>
				}
				
				<Button
					{...props}
					label={uploading ? `Uploading (${uploading}%)` : `Save File`} 
				/>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.name) {
		errors.name = 'Required'
	}
	
	if(!values.file) {
		errors.file = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'asset',
	validate
})(AssetsEdit);