import { brandConstants } from '../constants/brand';
import _ from 'lodash';

export default function(state = {}, action) {
	
	let brands = false;
	
	switch (action.type) {

		case brandConstants.GET_BRANDS:

			return { 
				...state, 
				brands: _.mapKeys(action.payload.data, 'id')
			};
			
		case brandConstants.GET_BRAND:

			brands = state.brands ? state.brands : {};
			brands[action.payload.data.id] = action.payload.data;
			
			return { 
				...state, 
				brands: brands
			};	
			
		case brandConstants.SAVE_CODE:
		case brandConstants.DELETE_CODE:
		
			brands = state.brands ? state.brands : {};
			brands[action.payload.data.brand_id].codes = action.payload.data.codes;
			
			return { 
				...state, 
				brands: brands
			};		
			
		default:
			return state;
	}
}