export function imageUrl(path) {
	
	if(checkUrl(path)){
		return path;
	}
	
	return `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_S3_REGION}.amazonaws.com/${path}`;
}

export function imageResize(path, width, height, fit) {
	
	if(checkUrl(path)){
		return path;
	}
	
	if(!width){
		width = null;
	}
	
	if(!height){
		height = null;
	}
	
	if(!fit){
		fit = 'cover';
	}

	//  OPTIONS: https://sharp.pixelplumbing.com/
	
	const imageRequest = {
	    bucket: process.env.AWS_S3_BUCKET_NAME,
	    key: path,
	    edits: {
	        //grayscale: true,
	        resize: {
	          	width: width,
			  	height: height,
			  	withoutEnlargement: false,
			  	fit: fit,
			  	position: 'attention',
			  	background: '#FFF'
	        }
	    }
	};
	
	return `https://${process.env.REACT_APP_AWS_CLOUDFRONT}.cloudfront.net/${btoa(JSON.stringify(imageRequest))}`;
}

export function checkUrl(path) {
	let regex = new RegExp("^(https?)(:\/\/|(\%3A%2F%2F))", "i");
	return regex.test(path);
};

export { imageUrl as default };