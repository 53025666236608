import { api } from '../helpers/api';
import { hubConstants } from '../constants/hub';

export const getItems = (brand) => async dispatch => {
    
    try{
        const request = await api({ brand: brand }).get(`/hub/items`);
        
        dispatch({
			type: hubConstants.GET_ITEMS,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const getItem = (id) => async dispatch => {
    
    try{
        const request = await api().get(`/hub/details/${id}`);
        
        dispatch({
			type: hubConstants.GET_ITEM,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const getCategories = (brand) => async dispatch => {
    
    try{
        const request = await api({ brand: brand }).get('/hub/categories');
        
        dispatch({
			type: hubConstants.GET_CATEGORIES,
			payload: request,
			brand: brand
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const saveItem = (id, values) => async dispatch => {
    
    let url = '/hub/save';
    
    if(id){
	    url += `/${id}`;
	}
	
    try{
        const request = await api().post(url, values);
        
        dispatch({
			type: hubConstants.SAVE_ITEM,
			payload: request
		});
		
		return request.data.id;
    }
    catch(error){
         console.log('error', error)
    }
}

export const saveCategory = (id, values) => async dispatch => {
    
    let url = '/hub/save_category';
    
    if(id){
	    url += `/${id}`;
	}
	
    try{
        const request = await api().post(url, values);
        
        dispatch({
			type: hubConstants.SAVE_CATEGORY,
			payload: request
		});
		
		return request.data.id;
    }
    catch(error){
         console.log('error', error)
    }
}

export const deleteItem = (id) => async dispatch => {
	
    try{
        const request = await api().delete(`/hub/delete/${id}`);
        
        dispatch({
			type: hubConstants.DELETE_ITEM,
			id: id
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const deleteCategory = (id) => async dispatch => {
	
    try{
        const request = await api().delete(`/hub/delete_category/${id}`);
        
        dispatch({
			type: hubConstants.DELETE_CATEGORY,
			id: id
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

