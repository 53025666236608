import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getBrands } from '../../actions/brand';
import { getAlert, saveAlert } from '../../actions/alert';
import _ from 'lodash';
import moment from 'moment';

const AlertEdit = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { id } = useParams();

	const alert = useSelector(state => state.alert);
	const brand = useSelector(state => state.brand);
	
	const [selectedBrand, setSelectedBrand] = useState(query.get('brand'));
	const [expire, setExpire] = useState(false);
	const [notify, setNotify] = useState(false);
	const [newUsers, setNewUsers] = useState(true);
	const [fixedDate, setFixedDate] = useState(true);
	const [enforce, setEnforce] = useState(false);
	const [type, setType] = useState('link');

	useEffect(() => {
		dispatch(getBrands());
		
		if(id){
			dispatch(getAlert(id));
		}
	}, []);	
	
	useEffect(() => {
			
		if(!id && brand.brands){
			
			if(_.size(brand.brands) == 1){
				setSelectedBrand(Object.values(brand.brands)[0].id);
			}else if(selectedBrand && !brand.brands[selectedBrand]){
				setSelectedBrand(false);
			}
		}
		
	}, [brand]);
	
	useEffect(() => {
		
		if(id){
			
			const item = alert.alerts[id];
			
			setType(item.type);
			setSelectedBrand(item.brands);
			
			props.change('title', item.title);
			props.change('description', item.description);
			
			switch(item.type){
				
				case 'link':
					props.change('url', item.data);
					break;
					
				case 'survey':
					props.change('survey', item.data);
					break;
			}
			
			if(item.expiry !== null){
				setExpire(true);
				props.change('expiry', item.expiry);
			}
			
			if(item.new_users == 0){
				setNewUsers(false);
			}

			if(item.fixed_date == 0){
				setFixedDate(false);
			}

			if(item.enforce == 1){
				setEnforce(true);
			}
		}
	}, [alert]);	
	
	if((id && (!alert.alerts || !alert.alerts[id])) || !brand.brands){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {
		
		if(!selectedBrand || selectedBrand.length == 0){
			return false;
		}
		
		let posting = {
			type: type,
			title: values.title,
			description: values.description,
			brands: selectedBrand,
			new_users: newUsers ? 1 : 0,
			fixed_date: fixedDate ? 1 : 0
		}
				
		switch(type){
			
			case 'link':
				posting.url = values.url;
				break;
				
			case 'survey':
				posting.survey = values.survey;
				break;	
		}
		
		if(expire){
			posting.expiry = values.expiry;
		}
		
		if(enforce){
			posting.enforce = 1;
		}
				
		dispatch(saveAlert(
			id ? id : false, 
			posting
		)).then((id) => {
			
			if(notify){
				props.history.push(`${props.parentPath}/compose?alert=${id}`);
			}else{
				props.history.push(props.parentPath);
			}
		});
	}
			
	return (
		<div>
			<div className="header">
				<h3>
					{id ? 'Edit Alert' : 'Add Alert'}
				</h3>
			</div>

			<form onSubmit={handleSubmit(Submit)}>			
								
				<Field
					label="Type"
					name="type"
					type="radio"
					noControl={true}
					className="inline autoWidth"
					options={[
						{
							value: 'link',
							label: 'Link'
						},
						{
							value: 'survey',
							label: "Survey"
						}
					]}
					checked={type}
					component={FormField}
					onChangeFunc={(e) => { setType(e.target.value) }}
				/>
								
				<Field 
					name="title" 
					type="text" 
					label="Title" 
					component={FormField} 
				/>
				
				<Field 
					name="description" 
					type="textarea" 
					label="Content" 
					component={FormField} 
				/>					
				
				{type == 'link' && 
					<Field 
						name="url" 
						type="url" 
						label="URL" 
						component={FormField} 
					/>
				}
				
				{type == 'survey' && 
					<Field 
						name="survey" 
						type="text" 
						label="Question Scout Survey ID" 
						component={FormField} 
					/>					
				}							

				<Field
					label="Expiry date"
					name="expire"
					type="radio"
					noControl={true}
					className="inline autoWidth"
					options={[
						{
							value: 0,
							label: 'No, keep running forever'
						},
						{
							value: 1,
							label: "Yes, expire at certain date"
						}
					]}
					checked={expire}
					component={FormField}
					onChangeFunc={(e) => { setExpire(e.target.value == '1' ? true : false) }}
				/>	
				
				{expire && 
				
					<Field 
						name="expiry" 
						type="date" 
						label="Expiry Date" 
						component={FormField} 
					/>
				}
				
				<Field
					label="Apply to new users"
					name="expire"
					type="radio"
					noControl={true}
					className="inline autoWidth"
					options={[
						{
							value: 1,
							label: "Yes, automatically apply to new users who sign up"
						},
						{
							value: 0,
							label: 'No, only apply to existing users when saved'
						}
					]}
					checked={newUsers}
					component={FormField}
					onChangeFunc={(e) => { setNewUsers(e.target.value == '1' ? true : false) }}
				/>	
				
				<Field
					label="Fixed Release Date"
					name="date"
					type="radio"
					noControl={true}
					className="inline autoWidth"
					options={[
						{
							value: 1,
							label: `Yes, date is fixed for all users (${id ? moment(alert.alerts[id].created.date).format('DD/MM/YYYY HH:mm') : 'today'})`
						},
						{
							value: 0,
							label: 'No, apply date based on when assigned to user'
						}
					]}
					checked={fixedDate}
					component={FormField}
					onChangeFunc={(e) => { setFixedDate(e.target.value == '1' ? true : false) }}
				/>	
				
				{_.size(brand.brands) > 1 && 
					<FormField
						name="assigned"
						label="Brand Assignment"
						placeholder="Select brand(s)"
						type="suggest"
						noControl={true}
						sortorder={true}
						isMulti
						options={_.map(brand.brands, (brand, key) => {
							return(
								{
									label: brand.name,
									value: brand.id
								}
							);
						})}
						selected={selectedBrand}
						onChangeFunc={(selected) => { 
							setSelectedBrand(_.map(selected, (item) => {
								return item.value;
							}));
						}}
					/>
				}
				
				<Field 
					name="enforce" 
					type="checkbox" 
					options={[
						{
							label: 'Enforce alert to takeover app',
							value: 1
						}
					]}
					checked={enforce}
					onChangeFunc={(event) => { 
						setEnforce(event.target.checked);
					}}
					component={FormField} 
				/>
				
				<Field 
					name="message" 
					type="checkbox" 
					options={[
						{
							label: 'Compose notification on save',
							value: 1
						}
					]}
					checked={notify}
					onChangeFunc={(event) => { 
						setNotify(event.target.checked);
					}}
					component={FormField} 
				/>
				
				<Button
					{...props}
					label="Save Item"
				/>
			</form>
		</div>
	);
}

const validate = values => {
	
	const errors = {}

	if(!values.from) {
		errors.from = 'Required'
	}
	
	if(!values.title) {
		errors.title = 'Required'
	}
	
	if(!values.description) {
		errors.description = 'Required'
	}
	
	if(!values.url) {
		errors.url = 'Required'
	}
	
	if(!values.survey) {
		errors.survey = 'Required'
	}
	
	return errors
}

export default reduxForm({
	form: 'alert',
	validate
})(AlertEdit);