import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import UserReducer from './user';
import AccountReducer from './account';
import BrandReducer from './brand';
import HubReducer from './hub';
import AssetReducer from './asset';
import GroupReducer from './group';
import QuestionnaireReducer from './questionnaire';
import HistoryReducer from './history';
import NotificationReducer from './notification';
import AlertReducer from './alert';

const appReducer = combineReducers({
	form: formReducer,
	account: AccountReducer,
	user: UserReducer,
	brand: BrandReducer,
	hub: HubReducer,
	asset: AssetReducer,
	group: GroupReducer,
	history: HistoryReducer,
	questionnaire: QuestionnaireReducer,
	notification: NotificationReducer,
	alert: AlertReducer
});

const rootReducer = (state, action) => {
	return appReducer(state, action)
}

export default rootReducer;
