import axios from 'axios';

export const api = (settings) => {

	let config = {
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			'API-KEY': process.env.REACT_APP_API_KEY
		}
	}
	
	if(settings){
		
		if(settings.brand){
			config['headers']['BRAND-ID'] = settings.brand;
			
		}
		
		if(settings.progressUpdater){
			
			config.onUploadProgress = (progressEvent) => {
				var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);			
				settings.progressUpdater(percentComplete);
			}
		}
	}
	
	if (localStorage.getItem('user_id')) {
		config['headers']['AUTH-KEY'] = localStorage.getItem('user_token');
		config['headers']['AUTH-ID'] = localStorage.getItem('user_id');
	}

	return axios.create(config);
}