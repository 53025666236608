import { api } from '../helpers/api';
import { alertConstants } from '../constants/alert';

export const getAlerts = () => async dispatch => {
    
    try{
        const request = await api().get('/alert/alerts');
        
        dispatch({
			type: alertConstants.GET_ALERTS,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const getAlert = (id) => async dispatch => {
    
    try{
        const request = await api().get(`/alert/alerts/${id}`);
        
        dispatch({
			type: alertConstants.GET_ALERT,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const saveAlert = (id, values) => async dispatch => {
    
    let url = '/alert/save';
    
    if(id){
	    url += `/${id}`;
	}
	
    try{
        const request = await api().post(url, values);
        
        dispatch({
			type: alertConstants.SAVE_ALERT,
			payload: request
		});
		
		return request.data.id;
    }
    catch(error){
         console.log('error', error)
    }
}

export const deleteAlert = (id) => async dispatch => {
	
    try{
        const request = await api().delete(`/alert/delete/${id}`);
        
        dispatch({
			type: alertConstants.DELETE_ALERT,
			id: id
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}