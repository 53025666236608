import { api } from '../helpers/api';
import { historyConstants } from '../constants/history';

export const getHistory = (options) => async dispatch => {
    
    try{
        const request = await api().post('/history/search', options);
        
        dispatch({
			type: historyConstants.GET_HISTORY,
			payload: request
		});
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}