import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { Field, reduxForm } from 'redux-form';
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import { imageResize, imageUrl } from '../../helpers/s3';
import Sidebar from "react-sidebar";
import Tabs from '../../components/chrome/tabs';
import _ from 'lodash';
import { getAssets, saveAsset } from '../../actions/asset';

const AssetsPicker = props => {

	const dispatch = useDispatch();
	const asset = useSelector(state => state.asset);
	
	useEffect(() => {
		console.log('useEffect', 'Picker')
		dispatch(getAssets());
	}, []);
	
	const [open, setOpen] = useState(false);
	const [tab, setTab] = useState('files');
	const [selected, setSelected] = useState([]);
	const [uploading, setUploading] = useState(0);
	
	const [newFile, setNewFile] = useState({ name: '', file: false });
	const [newError, setNewError] = useState({});
	
	let files = [];
	let filesContent = '';
	
	const selectFile = (id) => {
				
		if(asset.files[id] && props.types && !props.types.includes(asset.files[id].type)){
			console.log('file type cannot be selected');
			return;
		}

		let newSelected = selected;
										
		if(props.max){
			
			if(props.max == 1){
						
			}else if(newSelected.length >= props.max){
				return false;
			}
		}
			
		if(newSelected.includes(id)){
			newSelected.splice(newSelected.indexOf(id), 1);			
		}else{
			
			if(props.max == 1){
				newSelected = [id];
			}else{
				newSelected.push(id);
			}
		}
		
		setSelected(newSelected);
		
	}
				
	if(open){
		
		if(asset.files){
		
			let files = [];
	
			_.forEach(asset.files, (file, key) => {
								
				if(props.types){
						
					if(props.types.includes(file.type)){
						files.push(file);
					}
					
				}else{
					files.push(file);
				}
			});
							
			if(files.length == 0){
				
				filesContent = (
					<div>
						No files were found
					</div>	
				);
				
			}else{
				
				filesContent = (
					<div className="masonry">
						{_.map(files.reverse(), (file, key) => {
							
							if(typeof file == 'undefined'){
								return;
							}
							
							let thumbnail = '';
							let className = '';

							switch(file.type){
								
								case 'image':
									thumbnail = (
										<img src={imageResize(file.filename, 500)} />
									);
									
									className = 'image';
									break;
									
								default:
									
									let icon = 'question-circle';
									
									switch(file.type){
									
										case 'video':
											icon = 'play';
											break;	
											
										case 'pdf':
											icon = 'file-pdf';
											break;	
									}
								
									thumbnail = (
										<i className={`far fa-${icon}`}></i> 
									)
									break;
							}
																						
							return (
								<div 
									key={key}
									onClick={() => { 
										selectFile(file.id);
									}} 
									className={`brick hover ${selected.includes(file.id) ? 'selected' : ''}`}
								>
									<div className={`thumbnail ${className}`}>
										{thumbnail}
									</div>
									{file.title}									
								</div>
							);
						})}
					</div>
				);
			}
		}

		filesContent = (
			<div className="sidebar_container">
				<Tabs 
			 		options={[
				 		{
					 		label: 'Existing',
					 		id: 'files'
				 		},
				 		{
					 		label: 'Upload New',
					 		id: 'upload'
				 		}
				 	]}
			 		active={tab}
			 		onClick={(selected) => { setTab(selected) }}
			 	/>
			 	
			 	{tab == 'files' && 
				 	<div id="assets">
				 		
		    			{filesContent}
		    			
		    			<button type="button" className="btn" id="cta_fixed" onClick={() => { 
			    			props.onSelect(selected);
							setOpen(false); 
			    		}}>
							Save Selection
						</button>
				 	</div>
				}
				
				{tab == 'upload' && 
				 	
					<React.Fragment>	
						<Field
							label="Name"
							name="picker_name"
							type="text"
							selected={newFile.name}
							onChangeFunc={(e) => {								
								let newFiles = _.clone(newFile);
								newFiles.name = e.target.value
								setNewFile(newFiles)								
							}}
							component={FormField}
							className={newError.name ? 'is-invalid' : ''}
						/>
									
						<Field
							label="File"
							name="picker_file"
							type="file"
							selected={newFile.file}
							onChangeFunc={(e) => {								
								let newFiles = _.clone(newFile);
								newFiles.file = e.target.files[0]
								setNewFile(newFiles);							
							}}
							component={FormField}
							className={newError.file ? 'is-invalid' : ''}
						/>
								
						<div className="cta">
							<Button 
								label={uploading ? `Uploading (${uploading}%)` : `Save File`} 
								type="button"
								onClick={() => {
									
									let errors = {};
									
									if(!newFile.name){
										errors.name = 'Required';
									}
									
									if(!newFile.file){
										errors.file = 'Required';
									}
									
									setNewError(errors);	
									
									if(_.isEmpty(errors)){
										
										const posting = new FormData(); 
	     
										posting.append( 
											"file", 
											newFile.file, 
											newFile.name 
										); 
										
										posting.append('name', newFile.name);
																						
										setUploading(0);
										
										dispatch(saveAsset(
											false, 
											posting, 
											(percent) => {
												console.log('percent', percent)
												setUploading(percent);
											}
										)).then((id) => {
											
											if(id){
												selectFile(id);
												setNewFile({ name: '', file: false });
												setNewError({});
												setUploading(false);
												setTab('files')
											}
										});
									}
								}}
							/>
						</div>
					</React.Fragment>
				}				 	
			</div>
		)
	}
	
	let btnLabel = 'Select';
	let btnClassName = '';
	
	if(props.button){
		
		if(props.button.label){
			btnLabel = props.button.label;
		}
		
		if(props.button.className){
			btnClassName = props.button.className;
		}
	}
	
	return (
		<div>
			<button type="button" className={`btn ${btnClassName}`} onClick={() => { setOpen(true); }}>
				{btnLabel}
			</button>
			
			{open && 
				<Sidebar
			        rootClassName="sidebar"
			        sidebarClassName="sidebar_sidebar"
			        contentClassName="sidebar_content"
			        open={open}
			        styles={{ 
				        root: { display: open ? 'block' : 'none' }
				    }}
				    pullRight={true}
				    sidebar=""
			    >
			    	{filesContent}
			    </Sidebar>	
			}			
		</div>
	);
}

export default AssetsPicker;