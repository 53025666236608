import React, { useState } from 'react'
import { SketchPicker } from 'react-color'

const ColorPicker = props => {
	
	const [hex, setHex] = useState(props.color);
	const [open, setOpen] = useState(false);
	
	return (
		<div className="color-picker mb-10">
		
			{open &&
				<div className="cover" onClick={() => { setOpen(false) }}></div>
			}
			
			<div className="color-picker-settings">
				<button 
					type="button"
					className="swatch"
					onClick={() => { setOpen(true) }}
					style={{ backgroundColor: hex }}
				/>
				
				<label onClick={() => { setOpen(true) }}>
					{props.label}{hex ? `: ${hex}` : null}
				</label>
			
				{open &&
					<div className="picker">
					
						<SketchPicker 
							color={hex} 
							onChange={(color) => {
								setHex(color.hex);
								props.onChange(color.hex);
							}} 
							disableAlpha={true}
							presetColors={[]}
						/>
					</div>
				}
			</div>
		</div>
	)
}

export default ColorPicker