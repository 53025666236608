import React from 'react';
import { Link } from 'react-router-dom';
import { Textfit } from 'react-textfit';

const InfoBox = ({ title, title_sub, value, value_last, value_type, url, onClick, cta, selected, selectable, className }) => {

	let { subtitle, changed, cta_button } = '';
	let tip = '';
	
	className += ' info-box';
	
	if(title_sub){
		subtitle = (
			<div className="info-box-subtitle">
				{title_sub}
			</div>
		);
	}
		
	if(value_last){
		
		if(value !== value_last){

			let diff = Math.abs((100/value_last)*(value-value_last));	
			let classIcon = 'down';

			if(value > value_last){
				classIcon = 'up';
				className += ' green';
			}else{
				className += ' red';
			}
			
			changed = (
				<small>
					<i className={`far fa-arrow-${classIcon}`}></i>
					{Math.round(diff)}%
				</small>
			);
		}
	}
	
	if(value_type){
		
		switch(value_type){
				
			case 'percent':
				value = `${value}%`;
				break;
		}
	}

	if(cta){
			
		className += ' cta';
		
		if(cta == 'spacer'){
			className += ' cta-spacer';
		}else{
			
			let cta_icon = '';
			let cta_type = 'primary';
			
			
			if(cta.icon){
				cta_icon = <i className={`os-icon icon-feather-${cta.icon}`} />;
			}
			
			if(cta.type){
				cta_type = cta.type;
			}
			
			if(cta.url.substr(0, 6) == 'mailto'){
				cta_button = (
					<div className="info-box-cta">
						<a href={cta.url} className={`btn btn-${cta_type} mt-4 full-width`}>
							{cta_icon}
							<span>{cta.label}</span>
						</a>
					</div>
				);
			}else{
				cta_button = (
					<div className="info-box-cta">
						<Link to={cta.url} className={`btn btn-${cta_type} mt-4 full-width`}>
							{cta_icon}
							<span>{cta.label}</span>
						</Link>
					</div>
				);
			}	
		}	
	}
	
	title = (
		<div className="info-box-title">
			{title}
			{subtitle}
		</div>
	);
	
	let content = (
		<div className="info-box-content">
					
			<div className="info-box-total">
				<div>
					<strong>
					
						<Textfit
					        mode="single"
					        max="25">
					        {value}
					    </Textfit>
					      
				    </strong>
					{changed}
				</div>
			</div>
		</div>
	)
	
	if(url){
		
		return (
			<Link to={url} className="selectable" data-tip={tip}>
				{content}
			</Link>
		);
		
	}else {
		
		if(onClick){
			//className += ' btn';
		}else{
			onClick = () => {};
		}
		
		if(selected){
			className += ' active';
		}
		
		if(selectable){
			className += ' selectable';
		}
		
		return (
			<div className={className} onClick={onClick} data-tip={tip}>
				{title}
				{content}
				{cta_button}
			</div>
		);
	}	
}

export default InfoBox;
