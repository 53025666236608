import { alertConstants } from '../constants/alert';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {

		case alertConstants.GET_ALERTS:

			return { 
				...state, 
				alerts: _.mapKeys(action.payload.data, 'id')
			};	
			
		case alertConstants.GET_ALERT:
		case alertConstants.SAVE_ALERT:

			let alerts = state.alerts ? state.alerts : {};
			
			alerts[action.payload.data.id] = action.payload.data;
			
			return { 
				...state, 
				alerts: alerts
			};	
			
		case alertConstants.DELETE_ALERT:
		
			alerts = state.alerts ? state.alerts : {};
			
			if(alerts[action.id]){
				delete(alerts[action.id]);
			}
			
			return { 
				...state, 
				alerts: alerts
			};
			
		default:
			return state;
	}
}