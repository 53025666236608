import { hubConstants } from '../constants/hub';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {

		case hubConstants.GET_CATEGORIES:
		case hubConstants.SAVE_CATEGORY:
		
			let categories = {};
			let brands = _.uniqBy(action.payload.data, 'brand_id');
			
			_.forEach(brands, (brand, key) => {
				categories[brand.brand_id] = _.mapKeys(_.filter(action.payload.data, { brand_id: brand.brand_id }), 'id');
			});
			
			return { 
				...state, 
				categories: categories,
				categories_all: _.mapKeys(action.payload.data, 'id')
			};
			
		case hubConstants.GET_ITEMS:
		
			return { 
				...state, 
				items: _.mapKeys(action.payload.data, 'id')
			};		
			
		case hubConstants.GET_ITEM:
		case hubConstants.SAVE_ITEM:
		
			let items = state.items ? state.items : {};
			
			items[action.payload.data.id] = action.payload.data;
			
			return { 
				...state, 
				items: items
			};
		
		case hubConstants.DELETE_ITEM:
		
			items = state.items ? state.items : {};
			
			if(items[action.id]){
				delete(items[action.id]);
			}
			
			return { 
				...state, 
				items: items
			};	
			
		case hubConstants.DELETE_CATEGORY:
		
			categories = state.categories ? state.categories : {};
			let categories_all = state.categories_all ? state.categories_all : {};
			
			if(items[action.id]){
				delete(items[action.id]);
			}
			
			if(categories_all[action.id]){
				delete(categories_all[action.id]);
			}
			
			return { 
				...state, 
				categories: categories,
				categories_all: categories_all
			};			
			
		default:
			return state;
	}
}