import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import FormField from '../../components/form/field';
import Button from '../../components/chrome/button';
import Loading from '../../components/chrome/loading';
import { getUser, saveUserGroups } from '../../actions/user';
import { getBrand } from '../../actions/brand';
import { getGroups } from '../../actions/group';
import _ from 'lodash';

const UsersGroups = props => {

	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const { user_id, brand_id } = useParams();
	
	if(!user_id || !brand_id){
		props.history.push(props.parentPath);
	}

	const group = useSelector(state => state.group);
	const brand = useSelector(state => state.brand);
	const user = useSelector(state => state.user);

	const [selectedGroups, setSelectedGroups] = useState([]);
	
	useEffect(() => {
		dispatch(getUser(user_id));
		dispatch(getBrand(brand_id));
		dispatch(getGroups(brand_id));
	}, []);	
	
	useEffect(() => {
		
		if(user.users && user.users[user_id] && user.users[user_id].groups){
			setSelectedGroups(user.users[user_id].groups);
		}
	}, [user]);
	
	if(!user.users || !user.users[user_id] || !brand.brands || !brand.brands[brand_id] || !group.groups){
		
		return (
			<Loading />
		);
	}
	
	const Submit = (values, dispatch, props) => {

		let posting = {
			brand: brand_id, 
			groups: selectedGroups
		}
				
		dispatch(saveUserGroups(
			user_id, 
			posting
		)).then((id) => {
			props.history.push(props.parentPath);
		});
	}

	return (
		<div>
			<div className="header">
				<h3>
					{brand.brands[brand_id].name} Groups for {user.users[user_id].forename} {user.users[user_id].surname}
				</h3>
			</div>
			
			{!_.isEmpty(group.groups[brand_id]) && 

				<form onSubmit={handleSubmit(Submit)}>			
				
					<FormField
						name="groups"
						placeholder="Select groups(s) or leave blank for all open access"
						type="suggest"
						noControl={true}
						sortorder={true}
						isMulti
						options={_.map(group.groups[brand_id], (value, key) => {
							return(
								{
									label: value.name,
									value: value.id
								}
							);
						})}
						selected={selectedGroups}
						onChangeFunc={(selected) => { 
							setSelectedGroups(_.map(selected, (item) => {
								return item.value;
							}));
						}}
					/>
				
					<div className="cta">
						<Button
							{...props}
							label="Save"
						/>
					</div>
				</form>
			||
				<React.Fragment>
					There are no groups within this brand to assign!
				</React.Fragment>
			}
		</div>
	);
}

const validate = values => {
	
	const errors = {}
	
	return errors
}

export default reduxForm({
	form: 'groups',
	validate
})(UsersGroups);