import { api } from '../helpers/api';
import { questionnaireConstants } from '../constants/questionnaire';

export const getSummary = (id, groups, callback) => async dispatch => {

    try{
        const request = await api().post(`/questionnaire/summary/${id}`, { groups: groups });
        
        dispatch({
			type: questionnaireConstants.GET_SUMMARY,
			payload: request,
			id: id
		});
		
		if(callback){
			callback();
		}
		
		return true;
    }
    catch(error){
         console.log('error', error)
    }
}

export const getQuestions = () => async dispatch => {
	
	try{
		const request = await api().get('/questionnaire/questions');
		
		dispatch({
			type: questionnaireConstants.GET_QUESTIONS,
			payload: request
		});
		
		return true;
	}
	catch(error){
		 console.log('error', error)
	}
}