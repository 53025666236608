import { historyConstants } from '../constants/history';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {

		case historyConstants.GET_HISTORY:

			return { 
				...state, 
				history: action.payload.data
			};
			
		default:
			return state;
	}
}