import React from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import moment from 'moment';

const TimeAgoToday = ({ date, today, formats }) => {

	let renderFormats = {
		prefixAgo: null,
		prefixFromNow: null,
		suffixAgo: 'ago',
		suffixFromNow: 'from now',
		seconds: 'less than a minute',
		minute: 'about a minute',
		minutes: '%d minutes',
		hour: 'about an hour',
		hours: 'about %d hours',
		day: 'a day',
		days: '%d days',
		month: 'about a month',
		months: '%d months',
		year: 'about a year',
		years: '%d years',
		wordSeparator: ' '
	}
		
	if(formats){
		renderFormats = {...renderFormats, ...formats};
	}
	
	const formatter = buildFormatter(renderFormats);
	
	if(moment.isMoment(date)){
		date = moment(date);
	}
	
	if(today){
		
		if(date.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
			return (
				<span> 
					Today
				</span>
			);
		}
	}
		
	return (
		<TimeAgo 
			date={date}
			formatter={formatter}
		/>
	);
}

export default TimeAgoToday;