import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import _ from 'lodash';

const Notifications = props => {
	
	const query = new URLSearchParams(props.location.search);	
	const dispatch = useDispatch();
	
	return (
		<div>
		    <div id="page-header">
				<h2>
					Notifications
				</h2>
				
				<div id="actions">
					<Button
						label="New Notification"
						url="/notifications/compose"
					/>
				</div>
			</div>
			<div className="panel">
	    		<div className="content">
	    			Here we have a list of notifications sent
	    		</div>
			</div>	
		</div>
	);
}

export default Notifications;