import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getAssets } from '../../actions/asset';
import _ from 'lodash';
import TimeAgoToday from '../../components/chrome/timeago';
import { imageResize } from '../../helpers/s3';

const Assets = props => {
	
	const dispatch = useDispatch();
	const asset = useSelector(state => state.asset);
	
	useEffect(() => {
		console.log('useEffect', 'Assets')
		dispatch(getAssets());
	}, []);
	
	if(!asset.files){
		
		return (
			<Loading />
		);
	}
	
	const Files = () => {
		
		if(_.isEmpty(asset.files)){ 
			return (
				<EmptySection
					title="No Files"
					icon="fal fa-folder"
					description="There are currently no files on the system!"
					fullHeight={true}
				/>
			);

		}else{
			
			return (
				<div id="assets">							
					<div className="row">
						<div className="masonry">
							{_.map(_.sortBy(asset.files, ['created']).reverse(), (file, key) => {
								
								let thumbnail = '';
								let className = '';
	
								switch(file.type){
									
									case 'image':
										thumbnail = (
											<img src={imageResize(file.filename, 500)} />
										)
										
										className = 'image';
										break;
										
									default:
										
										let icon = 'question-circle';
										
										switch(file.type){
										
											case 'video':
												icon = 'play';
												break;	
												
											case 'pdf':
												icon = 'file-pdf';
												break;	
										}
									
										thumbnail = (
											<i className={`far fa-${icon}`}></i> 
										)
										break;
								}
																						
								return (
									<Link to={`/assets/file/${file.id}`} key={key} className="brick hover">
										<div className={`thumbnail ${className}`}>
											{thumbnail}
										</div>
										<div>
											{file.title}
										</div>
										<small>
											{file.created && 
												<TimeAgoToday 
													date={file.created.date} 
												/>
											}
										</small>									
									</Link>
								);
							})}	
						</div>		
					</div>
				</div>
			);				
		}
	}
	
	return (
		<div>
		    <div id="page-header">
				<h2>
					Files
				</h2>
				
				<div id="actions">
					<Button
						label="New File"
						url={`/assets/file`}
					/>
				</div>
			</div>
			
			<div className="panel">
	    		<div className="content">
	    			<Files />
	    		</div>
			</div>	
		</div>
	);
}

export default Assets;